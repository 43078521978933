import React, { useEffect } from "react";
import moment, { Moment } from "moment";
import useId from "@mui/material/utils/useId";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AriaLive } from "../../../data/accessibility/AccessibilityData";
import { formatDate, parseToMoment } from "../../../utility/utilityFunctions";
import CustomDatePicker from "../../../Components/CustomDatePicker";
import { appColors, appFonts } from "../../../theme";
import Aem from "../../../lib/aem/components/Aem";

const useStylesDOBForm = makeStyles(() => ({
  root: {
    position: "relative",
  },
  inputLine: {
    position: "relative",
  },
  formTextLabel: {
    position: "relative",
    fontSize: "14px",
    fontFamily: appFonts.medium,
    lineHeight: "26px",
    color: appColors.white,
    textAlign: "left",
    opacity: 0.7,
  },
  formTextLabelRequired: {
    position: "relative",
    marginLeft: "1px",
    marginRight: "1px",
  },
  errorText: {
    color: appColors.danger,
    fontFamily: appFonts.medium,
    fontSize: "16px",
    lineHeight: "26px",
    marginTop: "7px",
  },
}));

interface DOBFormProps {
  date: Moment | Date | string | null | undefined;
  required?: boolean;
  error?: boolean;
  aemIdDateOfBirth: string;
  aemIdDateError: string;
  onDateValueChange?: (v: Moment | null) => void;
  onDateStringChange?: (v: string) => void;
  onDisabledChange?: (v: boolean) => void;
}

const DOBForm: React.FC<DOBFormProps> = ({
  date,
  required = false,
  error = false,
  aemIdDateOfBirth = "BODY_DOB_VERIFY_TEXT_1",
  aemIdDateError = "BODY_DOB_VERIFY_ERROR_1",
  onDateValueChange,
  onDateStringChange,
  onDisabledChange,
}: DOBFormProps): JSX.Element => {
  const classes = useStylesDOBForm();

  const dobInputId = useId();
  const dobErrorId = useId();

  const dateValue = parseToMoment(date) || null;

  // remove any trailing * that indicate the field is required.
  // these are added by code below when the required prop is true.
  const fieldLabel: string = Aem.get(aemIdDateOfBirth, "Date of Birth").replace(/[*\s]+$/, "");

  const maxDate: Moment = moment().endOf("day"); // current date
  const minDate: Moment = moment(maxDate).subtract(125, "years"); // 125 years ago

  const handleDatePickerChanged = (v: Moment | null) => {
    if (onDateValueChange) {
      onDateValueChange(v);
    }
    if (onDateStringChange) {
      const dateStr: string = formatDate(v, "YYYY-MM-DD") || "";
      onDateStringChange(dateStr);
    }
  };

  useEffect(() => {
    if (date === "") {
      if (onDisabledChange) {
        onDisabledChange(true);
      }
      return;
    }
    // Might want to revisit this logic for disabling button with age restriction
    if (onDisabledChange) {
      onDisabledChange(false);
    }
  }, [date, onDisabledChange]);

  return (
    <Grid container className={classes.root}>
      <Grid container direction="column">
        <Grid container direction="column">
          <Grid item>
            <label className={classes.formTextLabel} htmlFor={dobInputId}>
              {fieldLabel}
              {required && <span className={classes.formTextLabelRequired}>*</span>}
            </label>
          </Grid>
          <Grid item className={classes.inputLine}>
            <CustomDatePicker
              id={dobInputId}
              value={dateValue}
              minDate={minDate}
              maxDate={maxDate}
              inputProps={{
                required,
                "aria-errormessage": dobErrorId,
              }}
              inputFormat="MM/DD/YYYY"
              error={error}
              onChange={handleDatePickerChanged}
            />
          </Grid>
        </Grid>
        {error && (
          <Grid item id={dobErrorId} className={classes.errorText} aria-live={AriaLive.polite}>
            <Aem cid={aemIdDateError}>Unable to verify birthdate, please try again.</Aem>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DOBForm;