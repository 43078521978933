import { PciDocTypes } from "../docData";
import { AppointmentTypeIds } from "../Fhir";
import { makeKeysMap } from "../../utility/utilityFunctions";
import { CaliVisitTypeNames } from "./CaliVisitTypes";
 
 
// Cali CDO specific constants and definitions
 
export const CaliDocTypeToPciDocTypeMap = {
  "Registration**":                       PciDocTypes.PATIENT_REGISTRATION,
  "Auth for Use and Disclosure**":        PciDocTypes.AUTHORIZATION,
  "NDN and NOA" :                         PciDocTypes.CA_NONDISCRIMINATION,                                            
};
 
export const CaliSupportedPciDocTypes: PciDocTypes[] = Object.values(CaliDocTypeToPciDocTypeMap);
 
const VT = makeKeysMap(CaliVisitTypeNames);
 
export const CaliVisitTypeToAppointmentTypeIdMap = {
  [VT.NEW]: AppointmentTypeIds.NewPatient,
  [VT.NSV]: AppointmentTypeIds.NewPatient,
  [VT.VNE]: AppointmentTypeIds.NewPatient,
  [VT.FUO]: AppointmentTypeIds.NewPatient,
  [VT.ROV]: AppointmentTypeIds.NewPatient,
  [VT.PTR]: AppointmentTypeIds.NewPatient,
 
  [VT.CPE]: AppointmentTypeIds.PhysicalExam,
  [VT.TCP]: AppointmentTypeIds.PhysicalExam,    // NOTE: not in spreadsheet ??
  [VT.PEF]: AppointmentTypeIds.PhysicalExam,
  [VT.HMV]: AppointmentTypeIds.PhysicalExam,
  [VT.WWE]: AppointmentTypeIds.PhysicalExam,
  [VT.TWW]: AppointmentTypeIds.PhysicalExam,
  [VT.NPV]: AppointmentTypeIds.PhysicalExam,
  [VT.NPE]: AppointmentTypeIds.PhysicalExam,
 
  // all VisitTypes not in this list will default to EstablishedPatient
  // see DefaultMissingVisitTypeAppointmentTypeId
  [VT.CON]: AppointmentTypeIds.EstablishedPatient,
  [VT.C30]: AppointmentTypeIds.EstablishedPatient,
  [VT.C40]: AppointmentTypeIds.EstablishedPatient,
  [VT.C45]: AppointmentTypeIds.EstablishedPatient,
};
 
// if the visitType or appoinmentTypeId is invalid or empty, then use NewPatient
export const DefaultInvalidVisitTypeAppointmentTypeId = AppointmentTypeIds.NewPatient;
 
// if the visitType or appoinmentTypeId is valid, but not in the mapped list above, use EstablishedPatient
export const DefaultMissingVisitTypeAppointmentTypeId = AppointmentTypeIds.EstablishedPatient;
 
// For Cali, map all forms to the same appointmentTypeId in the forms storage API
export const CaliDefaultDocAppointmentTypeId = AppointmentTypeIds.NewPatient;
 
 
// For Cali, telehealth visit types should have some required forms (not all forms required)
const CaliVisitTypeToRequiredTemplateKeysMap = {
  // Video Visit Types
  [VT.FUV]: [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION],
  [VT.TEV]: [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION],
  [VT.VNE]: [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION],
  [VT.NEV]: [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION],
  [VT.VPD]: [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION],
  [VT.VVR]: [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION],
 
  // Phone Visit Types
  [VT.FPN]: [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION],
  [VT.PHN]: [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION],
  [VT.PSE]: [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION],
  [VT.SPH]: [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION],
  [VT.TEL]: [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION],
  [VT.TEP]: [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION],
  [VT.PCO]: [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION],
  [VT.PSC]: [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION],
  [VT.PRC]: [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION],
  [VT.PRS]: [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION],
  [VT.PHL]: [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION],
  [VT.PHS]: [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION],
};
 
const CaliDefaultVisitTypeToRequiredTemplateKeys = [ PciDocTypes.PATIENT_REGISTRATION,PciDocTypes.AUTHORIZATION,PciDocTypes.CA_NONDISCRIMINATION];
 
// returns list of required required docTypes for this cdoVisitType.  defaults to false
// NOTE: this list may have items which are not expected nor displayed
// and may get ignored by logic elsewhere.
export const GetCaliRequiredDocTypesByCdoVisitType = (cdoVisitType: string): PciDocTypes[] => {
  return CaliVisitTypeToRequiredTemplateKeysMap[cdoVisitType] || CaliDefaultVisitTypeToRequiredTemplateKeys;
};
 
// check if the docType is required for this cdoVisitType.  defaults to false
export const IsCaliDocTypeRequiredByCdoVisitType = (docType: PciDocTypes, cdoVisitType: string): boolean => {
  let requiredDocTypes: PciDocTypes[] = GetCaliRequiredDocTypesByCdoVisitType(cdoVisitType);
  return !!(docType && requiredDocTypes && requiredDocTypes.length > 0 && requiredDocTypes.includes(docType));
};
 
 
 
