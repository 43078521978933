import React, { useRef, useState } from "react";
import clsx from "clsx";
import useId from "@mui/material/utils/useId";
import { Grid, Dialog, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TransitionProps } from "@mui/material/transitions";
import { Document, Page, pdfjs } from "react-pdf";
import { useWindowWidth } from "@wojtekmaj/react-hooks";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { appColors, appFonts, appStyles, useAppTheme, appDialogTransparentPaperProps } from "../../theme";
import { OpenExternalLink } from "../../utility/linkUtilities";
import { AppButton, LinkButton } from "../Buttons";
import { ReactComponent as RightArrowSvg } from "../../assets/icons/arrow_right.svg";
import { ReactComponent as ExternalLinkSvg } from "../../assets/icons/external_link.svg";
import { PrivacyUrl, TermsOfUseUrl } from "../../data/docData";
import Aem from "../../lib/aem/components/Aem";
import "react-pdf/dist/esm/Page/TextLayer.css";


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();


const useStyles = makeStyles(()=> ({
  overlayScrim: {
    position: "fixed",
    height: "100%",
    overflow:"auto",
    ...appStyles.bgDarkGrayGrad,
  },
  overlayHeader: {
    position: "relative",
    marginTop: "30px",
    marginBottom: "5px",
    paddingLeft: "30px",
    paddingRight: "30px",
    color: appColors.white,
    fontSize: "22px",
    lineHeight: "26px",
  },
  overlayContent: {
    padding: "30px",
    fontFamily: appFonts.medium,
    color: appColors.white,
    fontSize: "18px",  
    lineHeight: "26px",  
  },
  menuTitle: {
    position: "relative",
    color: appColors.white,
    fontFamily: appFonts.bold,
    fontSize: "22px",
    lineHeight: "26px",
  },
  closeButtonContainer: {
    justifyContent: "center",
  },
  closeButton: {
    fontFamily: appFonts.medium,
    fontWeight: "unset",
    minWidth: "80px",
    minHeight: "32px",
    borderRadius: "16px",
    lineHeight: "initial",
    letterSpacing: "initial",
    color: appColors.white,

    "&.MuiButton-outlined": {
      textTransform: "initial",
      borderColor: appColors.white,
    },
  },
  listArea: {
    marginTop: "16px",
    marginBottom: "30px",
  },
  listTitle: {
    minHeight: "55px",
    marginBottom: "5px",
    borderBottom: "1px solid rgba(255,255,255,0.4)",
    fontFamily: appFonts.bold,
    fontSize: "18px",
  },
  listRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "47px",
    borderBottom: "1px solid rgba(255,255,255,0.4)",
    fontFamily: appFonts.medium,
    fontSize: "18px",
    
    "& a.ext-link": {
      flex: "1 0 auto",
      textDecoration: "none",
    },
  },
  listRowContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    "& svg": {
      width: "24px",
      height: "24px",
    }
  },
  linkLabel: {
  },
  rightArrowIcon: {
  },
  btnCancel: {
    fontFamily: appFonts.medium,
    fontWeight: "unset",
    maxWidth: "80px",
    maxHeight: "32px",
    borderRadius: "16px",
    lineHeight: "initial",
    letterSpacing: "initial",
    color: appColors.white,
    "&.MuiButton-outlined": {
      textTransform: "initial",
      borderColor: appColors.white,
    },
    textTransform: "none",
    backgroundColor: "rgba(255,255,255,0.1)",
    border: "1px solid #FFFFFF",
    fontSize: "16px",
  },
  pdfHeader: {
    fontFamily: appFonts.medium,
    fontSize: "22px",
    lineHeight: "40px",
    color: appColors.white,
    paddingTop: "20px",
    paddingBottom: "30px",
    paddingLeft: "16px",
    paddingRight: "16px",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  pdfPage: {
    "& .react-pdf__Document": {
      "& .react-pdf__Page": {
        "& .react-pdf__Page__canvas":{
         marginBottom: "15px" ,
        }
      },
    }
  }
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface MenuOverlayProps {
  ariaTitleId?: string;
  onClose?: () => void;
}

const MenuOverlay = (props: MenuOverlayProps) => {
  const classes = useStyles();
  const theme = useAppTheme();
  const width = useWindowWidth();

  const dialogTitleId = useId();

  const handleClose = () => {
    if (props?.onClose) {
      props.onClose();
    }
  };
  
  // Dialog modal  
  const termsLabel: string = Aem.get("BODY_TERMS_TEXT_4", "Terms of Use");
  const privacyLabel: string = Aem.get("BODY_TERMS_TEXT_5", "Privacy Policy");
  const patientResourcesLabel: string = Aem.get("BODY_TERMS_TEXT_8", "Patient Resource Center");
  const termsLink: string = TermsOfUseUrl;
  const privacyLink: string = PrivacyUrl;
  const patientResourcesUrl: string = Aem.get("BODY_TERMS_TEXT_9", "https://resources.optum.com");

  const [numPages, setNumPages] = useState<any>(0);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [activePdfName, setActivePdfName] = useState<string>("");
  const [activePdfLink, setActivePdfLink] = useState<any>("");

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const pageRefs = useRef({});

  const onItemClick = ({ pageNumber }) => {
    pageRefs.current[pageNumber].scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Grid container direction="column" className={clsx(classes.overlayScrim, theme.nowrap)}>
      <Grid container direction="row" justifyContent="space-between" className={clsx(classes.overlayHeader, theme.nowrap)}>
        <Grid item className={classes.menuTitle}>
          <Aem cid="ACTION_MENUBUTTON_TEXT_1">Menu</Aem>
        </Grid>
        <Grid item className={classes.closeButtonContainer}>
          <AppButton
            variant="outlined" 
            disableElevation
            className={classes.closeButton}
            trackName="close"
            trackLocation="overlay"
            onClick={handleClose}
          >
            <Aem cid="ACTION_CLOSEBUTTON_TEXT_1">Close</Aem>
          </AppButton>
        </Grid>
      </Grid>
      <Grid 
        container 
        direction="column" 
        className={clsx(classes.overlayContent, classes.listArea, theme.nowrap)}
      >
        <Grid 
          item 
          container 
          direction="row" 
          justifyContent="space-between" 
          alignItems="center" 
          className={classes.listTitle}
        >
          <Aem cid="BODY_TERMS_TEXT_6">Patient Support</Aem>
        </Grid>

        <Grid item className={classes.listRow}>
          <LinkButton onClick={() => {
            setActivePdfName(termsLabel);
            setActivePdfLink(termsLink); 
            setOpenDialog(true);
          }} trackName="terms" trackLocation="overlay">
            <Grid container className={classes.listRowContent}>
              <Grid item className={classes.linkLabel}>
                {termsLabel}
              </Grid>
              <Grid item className={classes.rightArrowIcon}>
                <RightArrowSvg />
              </Grid>
            </Grid>
          </LinkButton>
        </Grid>

        <Grid item className={classes.listRow}>
          <LinkButton onClick={() => {
            setActivePdfName(privacyLabel);
            setActivePdfLink(privacyLink); 
            setOpenDialog(true);
          }} trackName="privacy" trackLocation="overlay">
            <Grid container className={classes.listRowContent}>
              <Grid item className={classes.linkLabel}>
                {privacyLabel}
              </Grid>
              <Grid item className={classes.rightArrowIcon}>
                <RightArrowSvg />
              </Grid>
            </Grid>
          </LinkButton>
        </Grid>

        <Grid item className={classes.listRow}>
          <LinkButton onClick={() => {
            // open link in external window
            OpenExternalLink(patientResourcesUrl, "patient resources", "overlay");
          }} trackName="patient resources" trackLocation="overlay">
            <Grid container className={classes.listRowContent}>
              <Grid item className={classes.linkLabel}>
                {patientResourcesLabel}
              </Grid>
              <Grid item className={classes.rightArrowIcon}>
                <ExternalLinkSvg />
              </Grid>
            </Grid>
          </LinkButton>
        </Grid>
      </Grid>

      <Dialog
        fullScreen={true}
        disableRestoreFocus={true}
        open={openDialog}
        TransitionComponent={Transition}
        aria-labelledby={dialogTitleId}
        PaperProps={appDialogTransparentPaperProps}
      >
        <Grid
          style={{
            height: "100%",
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.pdfHeader}
          >
            <Grid
              item
              id={dialogTitleId}
              className={theme.headerTitle}
              style={{ marginBottom: "0px", width: "70%" }}
            >
              {activePdfName}
            </Grid>
            <Grid item style={{ display: "flex" }}>
              <AppButton
                variant="outlined"
                disableElevation
                className={classes.btnCancel}
                trackName="cancel"
                trackLocation="overlay"
                onClick={() => {
                  setOpenDialog(false);
                  setActivePdfName("");
                  setActivePdfLink(""); 
                  setNumPages(0);            
                }}>
                <Aem cid="ACTION_CLOSEBUTTON_TEXT_1">Close</Aem>
              </AppButton>
            </Grid>
          </Grid>
          {/* pdf content */}
          <Grid  className={classes.pdfPage}
            container
            direction="column"
            style={{ alignContent: "center", paddingBottom: "20px" }}
          >
            {activePdfLink !== undefined
              && (
                <Document
                  file={activePdfLink}
                  onLoadSuccess={onDocumentLoadSuccess}
                  externalLinkTarget="_blank"
                  onItemClick={onItemClick}
                >
                  {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map(page => (
                      <div key={page} ref={el => { pageRefs.current[page] = el; }}>
                        <Page
                          renderTextLayer={false}
                          pageNumber={page}
                          width={width?? 300}
                          loading=""
                        />
                        </div>
                    ))}
                </Document>
              )}
          </Grid>
        </Grid>
      </Dialog>

    </Grid>
  );
};

export default MenuOverlay;

