import React from "react";
import useId from "@mui/material/utils/useId";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InfoIcon from "@mui/icons-material/Info";
import { AriaLive } from "../../data/accessibility/AccessibilityData";
import { appColors } from "../../theme";
import Aem from "../../lib/aem/components/Aem";

const useStyles = makeStyles({
  errorScrim: {
    padding: "15px",
    fontSize: "14px",
    color: appColors.darkError,
    backgroundColor: "rgba(0,0,0,0.4)",
    fontWeight: 600,
  },
  icon: {
    fontSize: "18px",
  },
  text: {
    marginLeft: "11px",
    marginTop: "3px",
  },
});

interface ErrorBannerProps {
  id?: string;
  aemId: string;
  aemFallbackText?: string;
}

export const FormErrorBanner: React.FC<ErrorBannerProps> = ({
  id,
  aemId,
  aemFallbackText = "Please complete all required inputs.",
}) => {
  const classes = useStyles();
  const inputId = useId();

  return (
    <Grid
      id={id || inputId}
      container
      direction="row"
      className={classes.errorScrim}
      aria-live={AriaLive.polite}
    >
      <Grid item>
        <InfoIcon className={classes.icon} />
      </Grid>
      <Grid item className={classes.text}>
        <Aem cid={aemId}>{aemFallbackText}</Aem>
      </Grid>
    </Grid>
  );
};

export default FormErrorBanner;