import React, { useState } from "react";
import { Grid } from "@mui/material";
import { makeStyles} from "@mui/styles";
import PublishIcon from '@mui/icons-material/Publish'
import Button from "@mui/material/Button";
import useIsMounted from "../../lib/hooks/useIsMounted";
import { ReactComponent as CameraSvg } from "../../assets/icons/camera.svg"
import { appColors, appFonts, appStyles } from "../../theme";
import { tracker } from "../../appTracker";
import PhotoOrientations from "./PhotoOrientationEnums";
 
 
 
 
const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: "rgba(0,0,0,0.2)",
    color: appColors.white,
    fontSize: "18px",
    letterSpacing: "0.5px",
    lineHeight: "12px",
    textAlign: "center",
    borderRadius: "8px",
    textTransform: "none",
    width: "100%",
    height: "60px",
    paddingLeft: "43px",
    fontFamily: appFonts.medium,
    border: "1px solid rgba(255,255,255,0.2)",
 
    "& .MuiButton-label": {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
 
    "&:focus": {
      ...appStyles.focusVisible,
    },
  },
  retakeButton: {
    color: appColors.white,
    fontSize: "16px",
    letterSpacing: "0.5px",
    textAlign: "center",
    textTransform: "none",
    width: "100%",
    height: "60px",
    paddingLeft: "16%",
    fontFamily: appFonts.medium,
 
    "& .MuiButton-label": {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
   
    "&:focus": {
      ...appStyles.focusVisible,
    },
  },
  buttonLabel: {
    flex: "1 0 auto",
    paddingRight: "40px",
    paddingBottom: "5px",
  },
  iconCamera: {
    maxWidth: "30px",
  },
  uploadIcon:{
    fontSize: '2rem',
    height:'35px',
    width:'30px'
  },
  icon: {
    maxWidth: "30px",      
  },
  iconSmall: {
  },
}));
 
interface ButtonProps {
  retakeButtonCss?: boolean;
  label: string;
  label1: string;
  disabled?: boolean;
  goTo?: string;
  theme?: string;
  fullWidth?: boolean;
  trackName?: string;
  trackLocation?: string;
  buttonAction?: (...args) => void;
  orientation: PhotoOrientations;
}
 
 
 
 
const ClickPhotoUploadButtons: React.FC<ButtonProps> = ({
  trackName = "click photo",
  trackLocation = "body",
  ...props
}: ButtonProps) => {
 
  const classes = useStyles();
 
 
 
  const { label,label1,disabled, buttonAction } = props;
   
  // used to disable the button when an async click action is occuring
  // this prevents button mashing
  const [clickPending, setClickPending] = useState<boolean>(false);
  const isMounted = useIsMounted();
 
 
 
  const handleClick = (ShowCamScreen: boolean) => async()  => {
    tracker.click(trackName || "", trackLocation || "");
 
    if (buttonAction) {
      setClickPending(true);
 
      try {
        await buttonAction(ShowCamScreen);
      } catch (ex) {}
     
      if (isMounted()) {
        setClickPending(false);
      }
    }
  };
 
 
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3}}>
      <Grid item xs={12}>
      <Button
        className={props.retakeButtonCss ? classes.retakeButton : classes.button}
        startIcon={<CameraSvg className={(window.innerWidth < 330) ? classes.iconSmall : classes.icon} />}
        onClick={handleClick(true)}
        disabled={disabled || clickPending}
      >
        <Grid container justifyContent="center" className={classes.buttonLabel}>{label}</Grid>
      </Button>
      </Grid>
      <Grid item xs={12}>
     
      {
        props.orientation === PhotoOrientations.Front ?
 
        <label htmlFor="file-inputfront" >
         <Button
         className={props.retakeButtonCss ? classes.retakeButton : classes.button}
         startIcon={<PublishIcon className={classes.uploadIcon} />}
         onClick={handleClick(false)}
         component="span"
         disabled={disabled || clickPending}
         >
          <Grid container justifyContent="center" className={classes.buttonLabel}>{label1}</Grid>
         
        </Button>
        </label>:
 
        <label htmlFor="file-inputback"  >      
        <Button
         className={props.retakeButtonCss ? classes.retakeButton : classes.button}
         startIcon={<PublishIcon className={classes.uploadIcon} />}
         onClick={handleClick(false)}
         component="span"
         disabled={disabled || clickPending}
        >
         
          <Grid container justifyContent="center" className={classes.buttonLabel}>{label1}</Grid>
        </Button>
         
        </label>
      }
     
 
      </Grid>
    </Grid>
  );
};
 

 
export default ClickPhotoUploadButtons;