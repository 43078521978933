import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Grid, Step, StepIconProps, StepLabel, Stepper } from "@mui/material";
import {
  addTaskRouteChangeHandler,
  addTaskRouteNotFoundHandler,
  gotoNextTaskStep,
  gotoPrevTaskStep,
  removeTaskRouteChangeHandler,
  removeTaskRouteNotFoundHandler,
  setTaskRoute,
  setTaskRouteInitState,
  setTaskTransitionPending,
  removeBeforeTaskRouteChangeHandler,
  addBeforeTaskRouteChangeHandler,
} from "../../features/task/taskSlice";
import { ReactComponent as CheckmarkSvg } from "../../assets/icons/checkmark.svg";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { TaskStepConfig, ParentStep } from "./taskDefs";
import { TaskRouteContext } from "./TaskRouteContext";
import { appColors, appFonts, appStyles, useAppTheme } from "../../theme";
import { NavButton } from "../Buttons";
import {
  buildTaskRouteInfos,
  empyTaskRouteInfo,
  filterIncludedSteps,
  getStartTaskRoute,
  TaskRouteInfo,
} from "./TaskStepperHelpers";
import useIsMounted from "../../lib/hooks/useIsMounted";
import LoadingScrim from "../LoadingScrim";
import LoadingSkeleton from "../LoadingSkeleton";
import Header from "../Header";
import HDivider from "../HDivider";
import Aem from "../../lib/aem/components/Aem";

const useStyles = makeStyles(
  {
    root: {
      position: "relative",
      width: "100%",
      height: "100%",
    },
    headerTitle: {
      marginBottom: "0px",
      marginTop: "0px",
    },
    parentStepperContainer: {
      margin: "40px auto 20px auto",
      minWidth: "250px",
    },
    parentStepper: {
      color: appColors.white,

      "& .MuiStepConnector-root": {
        "& .MuiStepConnector-line": {
          borderWidth: "2px",
          borderColor: appColors.mediumGray2,
          transform: "translateX(2px)",
        },
        "&.Mui-active .MuiStepConnector-line": {
          borderColor: appColors.success2,
        },
        "&.Mui-completed .MuiStepConnector-line": {
          borderColor: appColors.success2,
        },
      },
    },
    parentStep: {},
    parentStepLabel: {
      "& .MuiStepLabel-iconContainer": {
        "& .step-icon": {
          ...appStyles.stepIconBase,
          color: appColors.mediumGray2,

          "&.active": {
            ...appStyles.stepIconActive,
            background: appColors.mediumGray1,
          },
          "&.completed": {
            ...appStyles.stepIconCompleted,
          },

          "&.active.step-1": {
            ...appStyles.stepOrangeGrad,
          },
          "&.active.step-2": {
            ...appStyles.stepGreenGrad,
          },
          "&.active.step-3": {
            ...appStyles.stepBlueGrad,
          },
          "&.active.step-4": {
            ...appStyles.stepBlueGrad,
          },
        },
      },
      "& .MuiStepLabel-labelContainer": {
        "& .MuiStepLabel-label": {
          marginLeft: "15px",
          fontFamily: appFonts.regular,
          fontSize: "18px",
          color: appColors.white,
          letterSpacing: "initial",

          "&.Mui-active": {
            fontFamily: appFonts.bold,
          },
          "&.Mui-completed": {
            fontFamily: appFonts.medium,
          },
        },
      },
    },
    navFooter: {
      justifyContent: "center",
      alignItems: "center",
    },
    dbgFooter: {
      bottom: "0",
      marginBottom: "0 !important",
      paddingBottom: "0 !important",
      transform: "translateY(20px)",
      overflow: "hidden",
    },
  },
  { name: "taskStepper" }
);

export const CustomStepIcon: React.FC<StepIconProps> = (
  props: StepIconProps
) => {
  const { icon, active, completed, className } = props;
  const stepNum = Number(icon);

  return (
    <div
      className={clsx(
        "step-icon step-" + stepNum,
        { active, completed },
        className
      )}
    >
      {completed ? <CheckmarkSvg /> : stepNum}
    </div>
  );
};

interface TaskStepperProps {
  config: TaskStepConfig; // step definitions
  refreshConfig: number;  // defaults to 0, increment to force refresh and rebuild the config and partially re-initialize the component
  startRoute?: string;
  startNumber?: number; // defaults to 1
  useLoadingSkeletons?: boolean;   // if true, use LoadingSkeletons, otherwise use LoadingScrim
  showDebug?: boolean;
  showDebugNav?: boolean;
  onInitParams?: (params: any, taskRouteInfo: TaskRouteInfo) => void;
  onBeforeRouteChange?: (newRoute: string, oldRoute: string, newRouteInfo: any, oldRouteInfo: any) => void;
  onRouteChange?: (newRoute: string, oldRoute: string, newRouteInfo: any, oldRouteInfo: any) => void;
  onRouteNotFound?: (newRoute: string, oldRoute: string, newRouteInfo: any, oldRouteInfo: any) => void;
}

/**
 * This is an application specific implementation integrated with
 * the material stepper component.
 * This component will keep track of stepper state and control the routing for the
 * steps and the total number of steps with filled and empty sections.
 *
 * example usage:
 *   <TaskStepper config={...} onInitParams={...} />
 */
const TaskStepper: React.FC<TaskStepperProps> = ({
  config,
  refreshConfig = 0,
  useLoadingSkeletons = true,
  showDebug = false,
  showDebugNav = false,
  onInitParams,
  onBeforeRouteChange,
  onRouteChange,
  onRouteNotFound,
  ...props
}: TaskStepperProps) => {
  const classes = useStyles();
  const theme = useAppTheme();
  const dispatch = useAppDispatch();
  const routerParams = useParams();

  const [routesInit, setRoutesInit] = useState<boolean>(false);
  const [lastRefreshConfig, setLastRefreshConfig] = useState<number>(0);
  const [firstRouteInit, setFirstRouteInit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const isMounted = useIsMounted();

  const taskRouteInit = useAppSelector<boolean>((state) => state.task.routeInit);
  const activeRoute: string = useAppSelector((state) => state.task.route);
  const pendingRoute: string = useAppSelector((state) => state.task.pendingNextRoute);
  const pendingStartRoute: string = useAppSelector((state) => state.task.pendingStartRoute);
  const transitionPending: boolean = useAppSelector<boolean>((state) => state.task.transitionPending);
  const taskRouteState: any = useAppSelector((state) => state.task);
  const { showMovePrev, showMoveNext, canMovePrev, canMoveNext } = taskRouteState;


  const taskRouteInfoInit: boolean = !!taskRouteState?.init;
  const taskRouteInfo = taskRouteInfoInit ? taskRouteState : empyTaskRouteInfo;
  const routeMap: any = taskRouteInfo.routeMap;
  const visibleRoutes: string[] = taskRouteInfo.visibleRoutes;
  const parentSteps: any[] = taskRouteInfo.parentSteps;
  const routeInfo: any = taskRouteInfoInit && routeMap ? routeMap[activeRoute] : undefined;
  
  // register event handlers before starting other init actions
  useEffect(() => {
    if (!onBeforeRouteChange) {
      return;
    }
    addBeforeTaskRouteChangeHandler(onBeforeRouteChange);

    return () => {
      removeBeforeTaskRouteChangeHandler(onBeforeRouteChange);
    };
  }, [dispatch, onBeforeRouteChange]);

  useEffect(() => {
    if (!onRouteChange) {
      return;
    }
    addTaskRouteChangeHandler(onRouteChange);

    return () => {
      removeTaskRouteChangeHandler(onRouteChange);
    };
  }, [dispatch, onRouteChange]);

  useEffect(() => {
    if (!onRouteNotFound) {
      return;
    }
    addTaskRouteNotFoundHandler(onRouteNotFound);

    return () => {
      removeTaskRouteNotFoundHandler(onRouteNotFound);
    };
  }, [dispatch, onRouteNotFound]);
  // end event handlers

  useEffect(() => {
    (async () => {
      if (!isMounted()) { 
        return; 
      }
      
      // protect against initializing routeInfo more than once
      if (!taskRouteInfoInit) {
        let taskRouteInfo: TaskRouteInfo = buildTaskRouteInfos(config);
        dispatch(setTaskRouteInitState(taskRouteInfo));

        if (onInitParams) {
          await onInitParams(routerParams, taskRouteInfo);
        }

        if (!isMounted()) {
          return;
        }
        
        setLastRefreshConfig(refreshConfig);
        setRoutesInit(true);
      } else if (refreshConfig > 0 && lastRefreshConfig !== refreshConfig) {
        let taskRouteInfo: TaskRouteInfo = buildTaskRouteInfos(config);
        dispatch(setTaskRouteInitState(taskRouteInfo));
        setLastRefreshConfig(refreshConfig);
      }
    })();
  }, [
    config,
    refreshConfig,
    lastRefreshConfig,
    dispatch,
    isMounted,
    onInitParams,
    routerParams,
    taskRouteInfoInit,
  ]);

  useEffect(() => {
    if (taskRouteInfoInit && routesInit && !activeRoute) {
      let startRoute: string = pendingStartRoute || getStartTaskRoute(visibleRoutes, config?.startRoute);
      if (startRoute) {
        dispatch(setTaskRoute(startRoute));
      }
    }
  }, [activeRoute, visibleRoutes, pendingStartRoute, config?.startRoute, dispatch, routesInit, taskRouteInfoInit]);

  useEffect(() => {
    if (!loading) {
      return;
    }

    // check for all loading conditions
    if (taskRouteInfoInit && routesInit) {
      setLoading(false);
    }
  }, [loading, routesInit, taskRouteInfoInit]);

  useEffect(() => {
    if (!isMounted()) { return; }

    if (!firstRouteInit && !taskRouteInit && activeRoute && routesInit) {
      let activeRouteInfo: any = routeMap[activeRoute];
      if (onBeforeRouteChange) {
        onBeforeRouteChange(activeRoute, "", activeRouteInfo, null);
      }
      if (onRouteChange) { 
        onRouteChange(activeRoute, "", activeRouteInfo, null);
      }
      setFirstRouteInit(true);
    }
  }, [activeRoute, firstRouteInit, taskRouteInit, isMounted, onBeforeRouteChange, onRouteChange, routeMap, routesInit]);

  if (loading || transitionPending || !routesInit || !routeInfo) {
    return (
      <Grid
        container
        direction="column"
        className={clsx(classes.root, theme.nowrap)}
      >
        {useLoadingSkeletons && (<LoadingSkeleton show={loading || transitionPending} />)}
        {!useLoadingSkeletons && (<LoadingScrim show={loading || transitionPending} />)}
      </Grid>
    );
  }

  // This is where we decide whether to show the MUI stepper screen
  // or the individual step screens
  let PageComponent: any = routeInfo?.parent?.component;
  let ChildComponent: any = routeInfo?.child?.component;
  let showPage: boolean = !!(!routeInfo?.included && !!PageComponent);
  let showChildStep: boolean = !!routeInfo?.child;
  let showParentStep: boolean = !showChildStep && !!routeInfo?.parent;

  const resolveProps = (props, routerParams) => {
    try {
      if (props && typeof props === "function") {
        props = props.apply(null, [routerParams]);
      }
    } catch (ex) {}
    return props;
  };

  let pageComponentProps: any = null;
  let childComponentProps: any = null;
  if (showPage && PageComponent && pageComponentProps) {
    pageComponentProps = resolveProps(pageComponentProps, routerParams);
  }
  if (showChildStep && ChildComponent && childComponentProps) {
    childComponentProps = resolveProps(childComponentProps, routerParams);
  }

  const getDebugInfo = () => {
    return {
      activeRoute,
      pendingRoute,
      routeInfo,
      parentSteps,
      showParentStep,
      showChildStep,
    };
  };

  const renderParentStepper = () => {
    let activeStep: number = 0;
    let totalSteps: number = 0;
    if (routeInfo) {
      activeStep = routeInfo.parentStepIdx;
      totalSteps = routeInfo.numParentSteps;
      if (activeStep === null || activeStep === undefined) {
        activeStep = 0;
      }
      if (activeStep >= 0 && totalSteps < activeStep) {
        totalSteps = activeStep;
      }
    }

    let visibleParentSteps: ParentStep[] = filterIncludedSteps(parentSteps);
    let parentDbgInfo = { activeStep, totalSteps };

    return (
      <React.Fragment>
        <Grid
          container
          direction="column"
          className={clsx(theme.navSection, theme.nowrap)}
        >
          <Header colorLogo={true} showMenuButton={true} />

          <Grid item>
            <h1 className={clsx(classes.headerTitle, theme.headerTitle)}>
              {routeInfo?.parent?.title}
            </h1>
          </Grid>

          {routeInfo?.parent?.subtitle && (
               <Grid className={clsx(theme.headerText)}>
              {routeInfo?.parent.subtitle}
            </Grid>
          )}

          <Grid item style={{ margin: "16px 0" }}>
            <HDivider color={routeInfo?.themeColor} />
          </Grid>

          {false && showDebug && (
            <pre style={{ color: "gold" }}>
              {JSON.stringify(parentDbgInfo, null, 2)}
            </pre>
          )}

          <div className={classes.parentStepperContainer}>
            <Stepper
              className={clsx(classes.parentStepper)}
              activeStep={activeStep}
              orientation="vertical"
            >
              {visibleParentSteps.map((stepInfo, stepIdx) => (
                <Step key={stepIdx} className={classes.parentStep}>
                  <StepLabel
                    StepIconComponent={CustomStepIcon}
                    className={classes.parentStepLabel}
                  >
                    {stepInfo?.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        </Grid>

        <Grid
          container
          direction="row"
          className={clsx(theme.navStickyFooter, classes.navFooter)}
        >
          <Grid item xs={3}>            
          </Grid>
          <Grid item xs={6}>
            <NavButton
              label={<Aem cid="ACTION_NEXTBUTTON_TEXT_1">Next</Aem>}
              size="large"
              accentColor={routeInfo?.themeColor}
              fullWidth={true}
              trackName="next"
              trackLocation="nav footer"
              onClick={async () => {
                await dispatch(setTaskTransitionPending(true));
                await dispatch(gotoNextTaskStep());
              }}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const renderFooter = () => {
    return (
      <Grid
        container
        direction="column"
        className={clsx(theme.navStickyFooter, classes.dbgFooter)}
      >
        <Grid
          container
          direction="row"
          style={{ position: "relative", flexWrap: "nowrap", width: "100%" }}
        >
          {showMovePrev && (
            <NavButton
              label={<Aem cid="ACTION_PREVBUTTON_TEXT_1">Prev</Aem>}
              size={showDebugNav ? "small" : "large"}
              accentColor={routeInfo?.themeColor}
              fullWidth={true}
              disabled={!canMovePrev}
              trackName="prev"
              trackLocation="nav footer"
              onClick={async () => {
                await dispatch(setTaskTransitionPending(true));
                await dispatch(gotoPrevTaskStep());
              }}
            />
          )}
          {showMoveNext && (
            <NavButton
              label={<Aem cid="ACTION_NEXTBUTTON_TEXT_1">Next</Aem>}
              size={showDebugNav ? "small" : "large"}
              accentColor={routeInfo?.themeColor}
              fullWidth={true}
              disabled={!canMoveNext}
              trackName="next"
              trackLocation="nav footer"
              onClick={async () => {
                await dispatch(setTaskTransitionPending(true));
                await dispatch(gotoNextTaskStep());
              }}
            />
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <TaskRouteContext.Provider value={routeInfo}>
      <Grid
        container
        direction="column"
        className={clsx(classes.root, theme.nowrap)}
      >
        {showPage && PageComponent && (
          <PageComponent {...routeInfo?.parent?.componentProps} />
        )}
        {!showPage && showChildStep && ChildComponent && (
          <ChildComponent {...routeInfo?.child?.componentProps} />
        )}
        {!showPage && !showChildStep && showParentStep && renderParentStepper()}

        {showDebug && (
          <pre style={{ color: "gold" }}>
            {JSON.stringify(getDebugInfo(), null, 2)}
          </pre>
        )}

        {showDebugNav && renderFooter()}
      </Grid>
    </TaskRouteContext.Provider>
  );
};


export default TaskStepper;
