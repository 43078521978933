import React, { useState } from 'react';
import './customstyles.css'
import { aemLanguageOptions, preferredPronounsItems, genderItems, sexAssignedBirthItems, maritalStatusItems, ethnicityItems, raceItems } from './staticdata';
import { apicall_PatientData } from './savedata';
import { useAppSelector } from '../../app/hooks';
import { Grid, InputLabel, TextField, Typography,List, ListItem} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface Props {
    // Define your component props here
}

const AuthorizationSpa: React.FC<Props> = () => {
    // Component logic goes here     

    const pdata: any = useAppSelector((state) => state.patient);
    const persona = useAppSelector((state) => state.preferences.persona);
    const relation = useAppSelector((state) => state.preferences.relationship);
    const representativeName=useAppSelector((state)=>state.preferences.representativeFullName);
    
    const [formData, setFormData] = useState({
        patientId: pdata.patientId,
        first_name: pdata.first_name,
        last_name: pdata.last_name,
        full_name: pdata.full_name,

    });
   
    /* Modal data end */

    const today = new Date();
    const formattedDate = `${String(today.getMonth() + 1).padStart(2, '0')}/${String(today.getDate()).padStart(2, '0')}/${today.getFullYear()}`;
    /* Change and Seelct events end */
    /*Save Modal construction start*/
    apicall_PatientData(formData);
    /*Save Modal construction end*/
    return (
        <>
            <div style={{ padding: '15px' }} id='mainDiv'>
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }} id="pageOne">
                    <form>
                        <Grid container spacing={2} alignItems="center" lineHeight={2.5}>
                            <Grid item xs={6}>
                                <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold' }}>
                                Autorización de uso y divulgación
                                </Typography>
                            </Grid>
                            <Grid item xs={6} textAlign='right'>
                                <img src={`${process.env.PUBLIC_URL}/Optum_logo.png`} alt="Optum Logo" style={{ width: '160px', height: '75px' }} />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            
                            <Typography variant="h6" gutterBottom >
                            Autorizo a <b>Optum y a su compañía matriz y organizaciones subsidiarias y afiliadas</b> a usar y divulgar mi información de salud individualmente identificable entre ellas con el fin de brindarme un mejor tratamiento, para la facilitación de pagos, la coordinación de la atención o la administración de casos. Optum se toma muy en serio la privacidad de nuestros pacientes y solo utilizará esta información según lo requiera y permita la ley.
                            </Typography>
                          
                        </Grid>
                        <Grid container item xs={12}>
                            
                            <Typography variant="h6" gutterBottom >
                            <b>Tipo de información:</b>Autorizo a estas entidades a usar y divulgar toda mi información de salud, que incluye información médica, farmacéutica, dental, de la vista, de salud mental, de consumo de sustancias, de VIH/ SIDA*, de psicoterapia, reproductiva, genética, de enfermedades contagiosas y de programas de atención de la salud. Esta información puede incluir información relacionada con visitas, admisiones, tratamiento, reclamos, administración de casos o coordinación de la atención.
                            </Typography>
                          
                        </Grid>
                        <Grid container item xs={12}>
                            
                        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                          Entiendo y acepto que:
                        </Typography>
                        <List>
                        <ListItem style={{ display: 'list-item', listStyleType: 'disc', marginLeft: '20px', fontSize: '1.25rem'}}>Esta autorización es voluntaria.</ListItem>
                        <ListItem style={{ display: 'list-item', listStyleType: 'disc', marginLeft: '20px', fontSize: '1.25rem'}}>No se me puede denegar el tratamiento, el pago de servicios de atención de la salud ni la inscripción o la elegibilidad para beneficios de atención de la salud si no firmo este formulario.</ListItem>
                        <ListItem style={{ display: 'list-item', listStyleType: 'disc', marginLeft: '20px', fontSize: '1.25rem'}}>Esta autorización vencerá dos años después de la fecha en que la firme. Puedo revocar o modificar esta autorización en cualquier momento si notifico por escrito a Optum; no obstante, la revocación o modificación no tendrá efecto en ninguna acción realizada antes de la fecha en que se reciba y se procese mi revocación.</ListItem>
                            
                        </List>
                          
                        </Grid>
                        <Grid container item xs={12}>
                            
                            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                            Certifico que he leído lo anterior y tengo derecho a solicitar una copia. Como paciente o tutor, curador o agente general del paciente, acepto los términos anteriores.
                            </Typography>
                          
                        </Grid>
                        
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                            <Grid item xs={7}>
                            <TextField id="standard-basic" label="" variant="standard" value={persona === "patient" ? `${formData.first_name} ${formData.last_name}` : representativeName} fullWidth inputProps={{style: { fontSize: '1.20rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '1.25rem' }}>Firma del paciente o representante personal</InputLabel>
                            </Grid>
                            <Grid item xs={5}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth inputProps={{style: { fontSize: '1.20rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '1.25rem' }}>Fecha</InputLabel>
                            </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction="column" style={{ minHeight: '100vh' }} spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                            <Grid item xs={6}>
                            <TextField id="standard-basic" label="" variant="standard" value={persona === "patient"? "" : representativeName} fullWidth inputProps={{style: { fontSize: '1.20rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '1.25rem' }}>Nombre del representante personal</InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                            <TextField id="standard-basic" label="" variant="standard" value={persona === "patient" ? "Self" : relation} fullWidth inputProps={{style: { fontSize: '1.20rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '1.25rem' }}>Relación con el paciente/menor</InputLabel>
                            </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ flexGrow: 1 }} />
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom sx={{ fontSize: '1rem' }}>
                            *Se necesita autorización especial para los resultados de la prueba de VIH.
                            </Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontSize: '1rem' }}>
                            Si usted es un tutor o un representante designado por un tribunal, debe proporcionar una copia de su autorización legal para representar al paciente.
                            </Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontSize: '1rem' }}>
                            La compañía no discrimina por motivos de raza, color, origen nacional, sexo, edad ni discapacidad en programas y actividades de salud. Ofrecemos servicios gratuitos para ayudarle a comunicarse con nosotros. Por ejemplo, cartas en otros idiomas o en letra grande. O bien, usted puede pedir un intérprete. Para pedir ayuda, llame al 1-800-403-4160, TTY 711. ATENCIÓN: Si habla español
                            (Spanish), hay servicios de asistencia de idiomas, sin cargo, a su disposición. Llame al 1-800-403-4160, TTY 711. 請注意 ：如果您說中文 (Chinese) ，我們免費為您提供語言協助服務 。請致電 ：1-800-403-4160, TTY 711 。
                            </Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontSize: '1rem' }}>
                            Optum es una marca registrada de Optum, Inc. en los Estados Unidos y en otras jurisdicciones. Todas las demás marcas comerciales son propiedad de sus respectivos titulares. Dado que mejoramos continuamente nuestros productos y servicios, Optum se reserva el derecho de cambiar las especificaciones sin previo aviso. Optum es una empresa que promueve la igualdad de oportunidades.
                            </Typography>
                            <Typography variant="body2" gutterBottom sx={{ fontSize: '1rem' }}>
                            © 2024 Optum, Inc. Todos los derechos reservados. 23685 03/24
                            </Typography>
                            <Typography variant="h6" gutterBottom textAlign="right">
                            1
                            </Typography>
                        </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </>
    );
};

export default AuthorizationSpa;