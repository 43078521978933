import React, { useState } from "react";
import clsx from "clsx";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { appColors, appFonts } from "../../theme";
import useIsMounted from "../../lib/hooks/useIsMounted";
import { tracker } from "../../appTracker";

const useStyles = makeStyles(() => ({
  root: {
    width: "unset",
  },
  button: {
    color: appColors.white,
    padding: "12px",

    "&.MuiButton-root": {
      fontFamily: appFonts.medium,
      fontSize: "16px",
      textTransform: "none",
    },
    "&.MuiButton-text, &.MuiButton-contained, &.MuiButton-outlined": {
      borderWidth: 3,
      "&.MuiButton-root": {
        fontFamily: appFonts.semibold,
        fontSize: "18px",
        lineHeight: "initial",
        textTransform: "initial",
      },
      "&.MuiButton-root:hover": {
        backgroundColor: appColors.whiteA03,
      },
    },
    "&.MuiButton-outlined": {
      "&.MuiButton-root": {
        backgroundColor: appColors.whiteA01,
      },
    },
    "&.MuiButton-root.Mui-disabled": {
      color: appColors.white,
    },
    "& .MuiButton-label": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "5px",
      color: appColors.white,
    },

    "&.darkFilledBackground": {
      "&.MuiButton-outlined": {
        backgroundColor: "#333333",
        "&.MuiButton-root": {
          backgroundColor: "#333333",
        },
      },
    },
  },
  fullWidth: {
    width: "100%",
  },
  rounded: {
    borderRadius: 30,
  },
  outlineBorder: {
    paddingTop: "2px",
  },
  "size-large": {
    padding: "12px",
  },
  "size-medium": {
    padding: "4px 8px 6px",
    "&.MuiButton-text, &.MuiButton-contained, &.MuiButton-outlined": {
      "&.MuiButton-root": {
        fontFamily: appFonts.medium,
      },
      "& .MuiButton-label": {
        fontSize: "16px",
        paddingTop: "4px",
        paddingBottom: "4px",
      },
    },
    "&.MuiButton-outlined": {
      borderWidth: 1,
      "&.MuiButton-root": {
        backgroundColor: appColors.whiteA01,
      },
    },
  },
  "size-small": {
    padding: "1px 8px 4px",
    "&.MuiButton-text, &.MuiButton-contained, &.MuiButton-outlined": {
      "&.MuiButton-root": {
        fontFamily: appFonts.medium,
      },
      "& .MuiButton-label": {
        fontSize: "16px",
        paddingTop: "4px",
        paddingBottom: "4px",
        letterSpacing: "0px",
      },
    },
    "&.MuiButton-outlined": {
      borderWidth: 1,
      "&.MuiButton-root": {
        backgroundColor: appColors.whiteA01,
      },
    },
  },
  disabled: {
    opacity: 0.5,
  },
  accentWhite: {
    "&.MuiButton-outlined": {
      borderColor: appColors.white,
    },
  },
  accentOrange: {
    "&.MuiButton-text, &.MuiButton-contained": {
      backgroundColor: appColors.orangeMid,
    },
    "&.MuiButton-outlined": {
      borderColor: appColors.orangeMid,
    },
  },
  accentGreen: {
    "&.MuiButton-text, &.MuiButton-contained": {
      backgroundColor: appColors.greenMid,
    },
    "&.MuiButton-outlined": {
      borderColor: appColors.greenMid,
    },
  },
  accentPurple: {
    "&.MuiButton-text, &.MuiButton-contained": {
      backgroundColor: appColors.purpleMid,
    },
    "&.MuiButton-outlined": {
      borderColor: appColors.purpleMid,
    },
  },
  accentBlue: {
    "&.MuiButton-text, &.MuiButton-contained": {
      backgroundColor: appColors.blueMid,
    },
    "&.MuiButton-outlined": {
      borderColor: appColors.blueMid,
    },
  },
}));

interface NavButtonProps {
  label?: React.ReactElement | string;
  variant?: "text" | "outlined" | "contained" | undefined;
  size?: "small" | "medium" | "large" | undefined;
  className?: any;
  disabled?: boolean;
  goTo?: string;
  rounded?: boolean;
  accentColor?: string;
  darkFilledBackground?: boolean;
  fullWidth?: boolean;
  trackName?: string;
  trackLocation?: string;
  // NOTE: make sure to use async/await inside handler to prevent button mashing
  onClick?: (...args) => void;
}

export const NavButton: React.FC<NavButtonProps> = ({
  label,
  variant = "outlined",
  size = "large",
  className,
  disabled = false,
  rounded = true,
  accentColor = "white",
  darkFilledBackground = true,
  fullWidth = true,
  trackName = "nav button",
  trackLocation = "nav footer",
  onClick,
}) => {
  const classes = useStyles();

  // used to disable the button when an async click action is occurring
  // this prevents button mashing
  const [clickPending, setClickPending] = useState<boolean>(false);
  const isMounted = useIsMounted();

  let sizeClass = classes["size-" + size];
  let darkBgClass = darkFilledBackground ? "darkFilledBackground" : null;

  let accentColorClass;
  switch (accentColor) {
    case "white":
      accentColorClass = classes.accentWhite;
      break;
    case "orange":
      accentColorClass = classes.accentOrange;
      break;
    case "green":
      accentColorClass = classes.accentGreen;
      break;
    case "purple":
      accentColorClass = classes.accentPurple;
      break;
    case "blue":
      accentColorClass = classes.accentBlue;
      break;
    default:
      accentColorClass = classes.accentWhite;
      break;
  }

  const handleClick = async (...args) => {
    tracker.click(trackName, trackLocation);

    if (onClick) {
      setClickPending(true);

      try {
        await onClick(...args);
      } catch (ex) {}

      if (isMounted()) {
        setClickPending(false);
      }
    }
  };

  return (
    <Grid container className={clsx(classes.root, { [classes.fullWidth]: fullWidth })}>
      <Button
        variant={variant}
        size={size}
        className={clsx(
          classes.button,
          darkBgClass,
          accentColorClass,
          sizeClass,
          className,
          {
            [classes.fullWidth]: fullWidth,
            [classes.rounded]: rounded,
            [classes.disabled]: disabled,
          }
        )}
        disabled={disabled || clickPending}
        disableFocusRipple={true}
        onClick={handleClick}
      >
        <div className={classes.outlineBorder}>{label}</div>
      </Button>
    </Grid>
  );
};

export default NavButton;