import React from "react";
import clsx from "clsx";
import { Moment } from "moment";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { parseToMoment } from "../utility/utilityFunctions";
import { appColors, appFonts } from "../theme";
import { AccAutoFillTokens } from "../data/accessibility/AccessibilityData";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    padding: "2px 0 0 0",
    border: "1px solid transparent",
  },
  rootError: {
    border: "1px solid red",
  },
  datePicker: {
    position: "relative",
    width: "100%",
    outline: "none",
    boxSizing: "border-box",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",

    "&.MuiFormControl-root.MuiTextField-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(21,40,49,1)"      // #152831
      },
    },
    
    "& .MuiInputBase-colorPrimary": {
      color: appColors.white,
      fontSize: "18px",
      fontFamily: appFonts.medium,
      "& .MuiInputBase-input": {
        textTransform: "uppercase",
      },
      "&.MuiInputBase-input": {
        textTransform: "uppercase",
      },
      "& .MuiSvgIcon-root": {
        color: appColors.white,
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(255,255,255,0.7)",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "rgba(255,255,255,0.4)",
    },
  },
}));

const usePaperStyles = makeStyles(() => ({
  root: {
    backgroundColor: appColors.mediumGray2,
    boxShadow: "none",
    backdropFilter: "blur(10px)",
    color:  appColors.white,

    "& .MuiSvgIcon-root": {
      color: appColors.white,
    },
    "& .MuiDayPicker-weekDayLabel": {
      color: appColors.white,
    },
    "& .MuiDayPicker-weekContainer": {
      "& .MuiButtonBase-root.MuiPickersDay-root": {
        backgroundColor: appColors.mediumGray2,
        borderColor: appColors.whiteA07,
        color: appColors.white,

        "&.MuiPickersDay-today": {
          borderColor: appColors.white,
        },
        "&.Mui-selected": {
          backgroundColor: appColors.white,
          borderColor: appColors.white,
          color: appColors.black,
        },
        "&:hover": {
          backgroundColor: appColors.whiteA01,
        }
      },
    },
  },
}));

interface CustomDatePickerProps {
  id?: string;
  value?: Moment | null | undefined;
  minDate?: Moment;
  maxDate?: Moment;
  inputFormat?: string;
  inputProps?: any;
  error?: boolean;
  onChange: (v: Moment | null) => void;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  id = undefined,
  value = null,
  minDate,
  maxDate,
  inputFormat = "MM/DD/YYYY",
  inputProps = undefined,
  error = false,
  onChange
}: CustomDatePickerProps) => {
  const classes = useStyles();
  const paperClasses = usePaperStyles();

  const paperProps: any = {
    classes: paperClasses,
  };

  return (
    <Grid 
      container 
      direction="column"
      className={clsx(classes.root, { [classes.rootError]: error })}
    >
      <Grid item>
        <DesktopDatePicker
          className={classes.datePicker}
          label=""
          inputFormat={inputFormat}        
          value={value || null}
          minDate={minDate}
          maxDate={maxDate}
          PaperProps={paperProps}
          onChange={(v) => {
            let m: Moment | null = parseToMoment(v) || null;
            if (onChange) { onChange(m); }
          }}
          renderInput={(params) => {
            return (
              <TextField
                id={id}
                {...params}
                inputProps={{ 
                  ...params?.inputProps,
                  ...inputProps
                }}
                variant="standard"
                autoComplete={AccAutoFillTokens.off}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CustomDatePicker;