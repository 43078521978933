import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import { Moment } from "moment";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { NavButton } from "../../Components/Buttons";
import DOBForm from "./verificationForms/DOBForm";
import { formatDate, thirteenYearsAgeCheck } from "../../utility/utilityFunctions";
import { BackButton } from "../../../src/Components/Buttons";
import QuestionVerificationForm from "./verificationForms/QuestionVerificationForm";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  findBestAppointment,
  updateAttempts,
  updateLinkVerified,
} from "../../features/appt/apptSlice";
import { updatePrefIdentityVerified } from "../../features/preferences/prefSlice";
import { updateUIErrorPopupContext } from "../../features/ui/uiSlice";
import {
  CheckPatientDob,
  CheckPatientAddress,
  UpdateLinkStatus,
} from "../../api/ValidationAPI";
import Header from "../../Components/Header";
import ProgressStepper from "../../Components/ProgressStepper";
import { appColors, appFonts, useAppTheme } from "../../theme";
import {
  gotoNextTaskStep,
  gotoPendingTaskStep,
  gotoTaskRoute,
  setTaskTransitionPending,
} from "../../features/task/taskSlice";
import Aem, { AemContentTypes } from "../../lib/aem/components/Aem";
import { tracker } from "../../appTracker";
import { 
  deletePatientWebAccessCodeAPI
} from "../../api/GetAppointmentAPI";

const useStyles = makeStyles({
  root: {
    position: "relative",
    display: "flex",
    height: "100%",
    color: appColors.white,
  },
  headerTitle: {
    marginTop: "0px",
  },
  headerContent: {},
  content: {
    padding: "0 16px",
  },
  label: {
    fontFamily: appFonts.bold,
    fontSize: "22px",
    marginTop: "20px",
    marginBottom: "20px",
  },
  dobForm: {
    marginTop: "24px",
    width: "100%",
    height: "100%",
    textAlign: "left",
  },
  questionVerificationForm: {
    height: "100%",
  },
  pgFunc: {
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
  },
});

const STEP_NUM_DOB: number = 1;
const STEP_NUM_ADDRESS: number = 2;
const MAX_FAILED_DOB_VERIFY_ATTEMPTS: number = 2;
  
interface VerificationScreenProps {
  verifyStep?: number | undefined;
}

const VerificationScreen: React.FC<VerificationScreenProps> = ({
  verifyStep = undefined,
  ...props
}: VerificationScreenProps) => {
  const classes = useStyles();
  const theme = useAppTheme();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  
 

  let stepLocation: number = 1;
  if (verifyStep !== undefined && verifyStep > 0) {
    stepLocation = verifyStep;
  } else {
    const urlStepLocation: number = Number(pathname.split("/verification/")[1]);
    stepLocation = urlStepLocation;
  }

  const bestApptId: string = useAppSelector<string>((state) => findBestAppointment(state.appt.allAppointmentInfos)?.apptId || "");
  const patientId = useAppSelector<string>((state) => state.patient.patientId);
  const routeThemeColor: string = useAppSelector<string>((state) => state.task.routeInfo?.themeColor || "blue");

  const persona = useAppSelector<string>((state) => state.preferences.persona);
  const [dobDateValue, setDobDateValue] = useState<Moment | null>(null);
  const [addressVerificationAnswer, setAddressVerificationAnswer] = useState<string>("");

  const [disableDateNavButton, setDisableDateNavButton] = useState<boolean>(true);
  const [disableAddrNavButton, setDisableAddrNavButton] = useState<boolean>(true);

  const [dobVerifyError, setDobVerifyError] = useState<boolean>(false);
  const [addressVerifyError, setAddressVerifyError] = useState<boolean>(false);

  const attemptedSubmissions = useAppSelector(
    (state) => state.appt.dobAttempts
  );

  const isReturningPatient: boolean = useAppSelector(
    (state) => state.appt.isReturningPatient
  );
  const patientHasAllForms: boolean = useAppSelector(
    (state) => state.appt.patientHasAllForms
  );
  const pendingNextRoute: string = useAppSelector(
    (state) => state.task.pendingNextRoute
  );
  const editMode: boolean = !!pendingNextRoute;
  const verified = useAppSelector((state) => state.appt.verified);

  const isNavButtonDisabled = (): boolean => {
    let disabled = false;
    if (stepLocation === STEP_NUM_DOB) {
      disabled = disableDateNavButton;
    } else if (stepLocation === STEP_NUM_ADDRESS) {
      disabled = disableAddrNavButton;
    }
    return disabled;
  };

  const doDobAgeCheck = async (): Promise<boolean> => {
    let ageCheckPassed = true;
    if (stepLocation === STEP_NUM_DOB) {
      if (persona !== "patient") {
        // We only want to check the age if the user is a patient
        // If they aren't, continue the process.
        ageCheckPassed = true;
      } else {
        ageCheckPassed = await thirteenYearCheck();
      }
    }
    return ageCheckPassed;
  }

  const thirteenYearCheck = async (): Promise<boolean> => {
    const isOlderThanThirteenYears = thirteenYearsAgeCheck(dobDateValue);
    if (!isOlderThanThirteenYears) {
      // If they are younger than thirteen years and one day,
      // it should be false and an error popup should show
      dispatch(updateUIErrorPopupContext({ component: "AgeErrorPopup" }));
      dispatch(setTaskTransitionPending(false));
      setDobDateValue(null);
      setDisableDateNavButton(true)
      return false;
    } else {
      return true;
    }
  };

  const updateLinkStatusAndGoToVerificationError = async () => {
    UpdateLinkStatus(bestApptId, "false");
    dispatch(updateLinkVerified(false));
    await dispatch(gotoTaskRoute("verificationError"));
  };

  const navToNextStep = async () => {
    if (stepLocation === STEP_NUM_ADDRESS && isReturningPatient && !editMode) {
      if (patientHasAllForms) {
        await dispatch(gotoPendingTaskStep("consent/review"));
      } else {
        await dispatch(gotoPendingTaskStep("consent"));
      }
    } else {
      await dispatch(gotoNextTaskStep());
    }
    return true;
  };

  // Commented out as the transition shows the popup twice
  // const Transition = React.forwardRef(function Transition(
  //   props: TransitionProps & {
  //     children: React.ReactElement<any, any>;
  //   },
  //   ref: React.Ref<unknown>
  // ) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });

  const threeAttemptVerifySwitch = async () => {
    // NOTE: make sure to use async/await to prevent button mashing of the NavButton
    switch (stepLocation) {
      case STEP_NUM_DOB:
        let ageCheckPassed = await doDobAgeCheck();
        if (ageCheckPassed) {
          await dobVerify();
        } else {
          setDisableDateNavButton(true);
        }
        break;
      case STEP_NUM_ADDRESS:
        await addressVerify();
        break;
      default:
        console.warn("There was an verification error");
        await navToNextStep();
        break;
    }
  };

  const dobVerify = async () => {
    let altDateStr: string = formatDate(dobDateValue, "MM/DD/YYYY");
    await CheckPatientDob(patientId, altDateStr).then(
      async (check: boolean) => {
        if (!check) {
          setDobVerifyError(true);
          setDobDateValue(null);
          setDisableDateNavButton(true)

          // for DOB verification, the user gets multiple attempts.
          // go to the error page once they ahve exceeded their max attempts.
          if (attemptedSubmissions >= MAX_FAILED_DOB_VERIFY_ATTEMPTS) {
            dispatch(setTaskTransitionPending(true));
            await deletePatientWebAccessCodeAPI(patientId).catch(() => false);
            await updateLinkStatusAndGoToVerificationError();
            return;
          }
          dispatch(updateAttempts(attemptedSubmissions + 1));
        } else {
          setDobVerifyError(false);
          
          dispatch(setTaskTransitionPending(true));
          await navToNextStep();
        }
      }
    );
  };

  const addressVerify = async () => {
    await CheckPatientAddress(patientId, addressVerificationAnswer).then(
      async (check: boolean) => {
        if (!check) {
          setAddressVerifyError(true);

          // for address verifications, you do not get multiple attempts.
          // if user fails, then we skip to the error page now.
          dispatch(setTaskTransitionPending(true));
          await deletePatientWebAccessCodeAPI(patientId).catch(() => false);
          await updateLinkStatusAndGoToVerificationError();
          return;
        } else {
          setAddressVerifyError(false);

          dispatch(setTaskTransitionPending(true));
          dispatch(updatePrefIdentityVerified(true));
          await navToNextStep();
        }
      }
    );
  };

  useEffect(() => {   
    if (verified) {      
      dispatch(gotoNextTaskStep());
    }
  }, [verified, dispatch]);
  
  // watch for form errors and send them to the analytics tracker
  useEffect(() => {
    tracker.watchFormErrors([
      { field: "dob", alert: "dob invalid", value: dobVerifyError },
      {
        field: "address",
        alert: "address invalid",
        value: addressVerifyError,
      },
    ]);
  }, [dobVerifyError, addressVerifyError]);

  const formVerificationSelection = () => {
    if (stepLocation === STEP_NUM_DOB) {
      return (
        <Grid item className={classes.dobForm}>
           <DOBForm
            date={dobDateValue}
            aemIdDateOfBirth="BODY_DOB_VERIFY_TEXT_1"
            aemIdDateError="BODY_DOB_VERIFY_ERROR_1"
            onDateValueChange={(v) => {
              setDobDateValue(v);
              setDisableDateNavButton(!v); 
            }}
            required={true}
            error={dobVerifyError}
          />
        </Grid>
      );
    } else if (stepLocation === STEP_NUM_ADDRESS) {
      return (
        <Grid item className={classes.questionVerificationForm}>
          <QuestionVerificationForm
            questionVerificationAnswer={addressVerificationAnswer}
            setQuestionVerificationAnswer={setAddressVerificationAnswer}
            setDisableButton={(v) => setDisableAddrNavButton(v)}
            error={addressVerifyError}
          />  
        </Grid>
      );
    } else {
      return <Grid>There was an error, please return to home page.</Grid>;
    }
  };

  let title: any = "Verification";
  let image: any = "Page function image";
  switch (stepLocation) {
    case STEP_NUM_DOB:
      title = <Aem cid="HEADER_DOB_VERIFY_TEXT_1">Verify your birthday</Aem>;
      image = "HEADER_DOB_LOGO_1";
      break;
    case STEP_NUM_ADDRESS:
      title = <Aem cid="HEADER_ADDRESS_VERIFY_TEXT_1">Verify your address</Aem>;
      image = "HEADER_ADDRESS_LOGO_LOCATION_1";
      break;
    default:
      break;
  }

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="column" className={theme.navContent}>
        <Grid
          container
          direction="column"
          className={clsx(theme.navHeader, theme.bgOrangeGrad)}
        >
          <Header showMenuButton={true} />

          <ProgressStepper step={stepLocation + 1} totalSteps={3} />

          <Grid container className={classes.pgFunc}>
            <Grid item>
              <h1 className={clsx(classes.headerTitle, theme.headerTitle)}>
                {title}
              </h1>
            </Grid>
            <Grid item>
              <Aem cid={image} type={AemContentTypes.imageUrl}></Aem>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" className={theme.navSection}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignContent="center"
          >
            {formVerificationSelection()}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        className={theme.navStickyFooter}
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={3}>
          <BackButton
            trackName="prev"
            trackLocation="nav footer"
            onClick={async () => {
              await dispatch(gotoTaskRoute("verify/identify"));
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <NavButton
            label={Aem.get("ACTION_NEXTBUTTON_TEXT_1", "Next")}
            accentColor={routeThemeColor}
            fullWidth={true}
            disabled={isNavButtonDisabled()}
            trackName="next"
            trackLocation="nav footer"
            onClick={async () => {
              await threeAttemptVerifySwitch();
            }}
          />
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </Grid>
  );
};


export default VerificationScreen;
