import React, { useRef, useState, useCallback, useEffect } from "react";
import clsx from "clsx";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Webcam from "react-webcam";
import { PhotoOrientations } from "./PhotoOrientationEnums";
import { ReactComponent as CapturePhotoSvg } from "../../assets/capture-photo-icon.svg";
import SuccessIcon from "../../assets/success-icon.png";
import { appColors, appFonts, useAppTheme } from "../../theme";
import { NavButton } from "../Buttons";
import { tracker } from "../../appTracker";
import Aem from "../../lib/aem/components/Aem";
import config from "../../config";
import axios from "axios";


const DEV_DEBUG_IMAGES: boolean = true && (config.deployEnv !== "production");

const useStyle = makeStyles({
  root: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  video: {
    width: "100%",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    textAlign: "center",
  },
  topHeading: {
    minHeight: "84px",
    padding: "16px",
    fontFamily: appFonts.medium,
    fontSize: "22px",
    lineHeight: "40px",
    backgroundColor: "rgba(0,0,0,0.3)",
  },
  description: {
    paddingTop: "60px",
  },
  cancelButton: {
    fontFamily: appFonts.medium,
    fontWeight: "unset",
    fontSize: "16px",
    lineHeight: "initial",
    letterSpacing: "initial",
    color: appColors.white,
    "&.MuiButton-outlined": {
      textTransform: "initial",
    },
  },
  clickIcon: {
    position: "fixed",
    bottom: "64px",
    left: "50%",
    transform: "translateX(-50%)",
    overflow: "hidden",
  },
  capturePhotoSvg: {
    fill: appColors.white,
    color: appColors.white,
  },
  overlayContainer: {
    position: "absolute",
    top: "84px",
    borderLeftStyle: "solid",
    borderLeftColor: "transparent",
    borderLeftWidth: 0.05 * window.innerWidth,
    borderRightStyle: "solid",
    borderRightColor: "transparent",
    borderRightWidth: 0.05 * window.innerWidth,
    borderTopStyle: "solid",
    borderTopColor: "transparent",
    borderTopWidth:
      0.5 * (0.75 * window.innerWidth - 0.3 * (window.innerHeight - 70)),
    borderBottomStyle: "solid",
    borderBottomColor: "transparent",
    borderBottomWidth:
      0.5 * (0.75 * window.innerWidth - 0.3 * (window.innerHeight - 70)),
  },
  overlayContainer1: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    paddingLeft: 0,
    width: window.innerWidth,
  },
  successOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 3,
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: "rgba(0,0,0,0.5)",
    textAlign: "center",
    justifyItems: "center",
  },
  successImage: {
    position: "absolute",
    width: window.innerWidth / 3,
    height: window.innerWidth / 3,
    zIndex: 4,
    top: 0.4 * window.innerHeight,
    left: 0.5 * window.innerWidth - window.innerWidth / 6,
  },
});

interface CaptureImageProps {
  title?: string;
  cancelLabel?: string;
  orientation: string;
  onChangeShowCaptureScreen: (val: boolean) => void;
  onChangeShowRetakeScreen: (val: boolean) => void;
  onSetImage: (imageSrc: string | null | undefined) => void;
  onCancel: () => void;
}

const CaptureImage: React.FC<CaptureImageProps> = ({
  title = "ID card",
  cancelLabel = "Cancel",
  orientation,
  onChangeShowCaptureScreen,
  onChangeShowRetakeScreen,
  onSetImage,
  onCancel,
}) => {
  const classes = useStyle();
  const theme = useAppTheme();
  const webcamRef = useRef<Webcam>(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [isLandscape, setIsLandscape] = useState(windowDimensions.height <= windowDimensions.width);
  const initialRatio = isLandscape
    ? 3 / 2
    : windowDimensions.width / windowDimensions.height <= 0.5
    ? 4 / 9
    : 2 / 3;
  const [ratio, setRatio] = useState(initialRatio);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  }

  useEffect(() => {
    function handleDimensions() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleDimensions);
    return () => window.removeEventListener("resize", handleDimensions);
  }, []);

  useEffect(() => {
    const dataval = windowDimensions.width / windowDimensions.height;
    setRatio(dataval);
    setIsLandscape(windowDimensions.height <= windowDimensions.width);

    function handleResize() {
      if (isLandscape) {
        setRatio(3 / 2);
      } else if (!isLandscape && dataval < 0.5) {
        setRatio(4 / 9);
      } else if (!isLandscape && dataval >= 0.5) {
        setRatio(2 / 3);
      }
    }
    handleResize();
  }, [isLandscape, windowDimensions]);

  const videoConstraints = {
    facingMode: { exact: "environment" },
    aspectRatio: ratio,
  };

  const capture = useCallback(async () => {
    let imageSrc = webcamRef?.current?.getScreenshot();

    if (DEV_DEBUG_IMAGES && !imageSrc) {
      const PUBLIC_URL = process.env.PUBLIC_URL || "";
      const imgFrontUrl: string = `${PUBLIC_URL}/debugImages/insurance_card_front.png`;
      const imgBackUrl: string = `${PUBLIC_URL}/debugImages/insurance_card_back.png`;
      const imgUrl: string = orientation === "Back" ? imgBackUrl : imgFrontUrl;
      const imgRes = await axios.get(imgUrl, { responseType: "arraybuffer" });
      if (imgRes?.data) {
        imageSrc = "data:image/png;base64," + Buffer.from(imgRes.data).toString("base64");
      }
    }

    setShowSuccess(true);
    setTimeout(() => {
      onSetImage(imageSrc);
      onChangeShowCaptureScreen(false);
      onChangeShowRetakeScreen(true);
    }, 1000);
  }, [webcamRef, orientation, onSetImage, onChangeShowCaptureScreen, onChangeShowRetakeScreen]);

  return (
    <Grid container direction="column" className={clsx(classes.root, theme.nowrap)}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" className={clsx(classes.topHeading, theme.navSection, theme.nowrap)}>
        <Grid item>{title}</Grid>
        <Grid item>
          <NavButton
            className={classes.cancelButton}
            label={cancelLabel}
            size="medium"
            variant="outlined"
            fullWidth={false}
            trackName="cancel"
            trackLocation="overlay"
            onClick={onCancel}
          />
        </Grid>
      </Grid>

      <div>
        <Grid>
          {showSuccess ? (
            <div className={classes.successOverlay}>
              <img src={SuccessIcon} className={classes.successImage} alt="" />
            </div>
          ) : (
            ""
          )}
        </Grid>
        <Grid item>
          <div>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              screenshotQuality={1}
              videoConstraints={videoConstraints}
              className={classes.video}
              onUserMediaError={(e) => {
                if (DEV_DEBUG_IMAGES) {
                  console.log("camera not working, using dev debug images");
                  return;
                }
                console.log("camera not working, returning to parent screen");
                console.log(e);
                tracker.formError([{ field: "camera", alert: "camera error" }]);
                onChangeShowCaptureScreen(false);
              }}
            />
          </div>
        </Grid>
      </div>
      <Grid item className={classes.clickIcon}>
        <CapturePhotoSvg onClick={capture} className={classes.capturePhotoSvg} />
      </Grid>
    </Grid>
  );
};

export default CaptureImage;