import React, { useState } from "react";
import { Button } from "@mui/material";
import useIsMounted from "../../lib/hooks/useIsMounted";
import { tracker } from "../../appTracker";

type AppButtonProps = any & {
  disabled?: boolean;
  trackName?: string;
  trackLocation?: string;
  // NOTE: make sure to use async/await inside handler to prevent button mashing
  onClick?: (...args) => void;
};

export const AppButton: React.FC<AppButtonProps> = ({
  disabled = false,
  trackName = "app button",
  trackLocation = "body",
  onClick,
  ...buttonProps
}) => {
  // used to disable the button when an async click action is occurring
  // this prevents button mashing
  const [clickPending, setClickPending] = useState<boolean>(false);
  const isMounted = useIsMounted();

  const handleClick = async (...args) => {
    tracker.click(trackName, trackLocation);

    if (onClick) {
      setClickPending(true);

      try {
        await onClick(...args);
      } catch (ex) {}

      if (isMounted()) {
        setClickPending(false);
      }
    }
  };

  return (
    <Button
      {...buttonProps}
      disabled={disabled || clickPending}
      onClick={handleClick}
    />
  );
};

export default AppButton;