import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { appMaxWidth, appStyles, AppThemeColorKeys, useAppTheme } from "../../theme";
import { useAppSelector } from "../../app/hooks";
import LoadingSkeleton from "../LoadingSkeleton";

const useStyles = makeStyles(()=> ({
  root: {
    maxWidth: appMaxWidth,
    margin: "0 auto",
    ...appStyles.bgTransparent,
    pointerEvents: "none",
  },
}));

interface TransitionLoadingProviderProps {
}

export const TransitionLoadingProvider: React.FC<TransitionLoadingProviderProps> = (props: TransitionLoadingProviderProps) => {
  const classes = useStyles();
  const theme = useAppTheme();
  
  const transitionPending = useAppSelector<boolean>((state) => state.task.transitionPending);
  if (!transitionPending) { return null; }

  return (
    <Grid
        container
        direction="column"
        className={clsx(classes.root, theme.absxy, theme.nowrap)}
      >
        <LoadingSkeleton show={transitionPending} scrimBgColor={AppThemeColorKeys.darkGray} />
      </Grid>
  );
};


export default TransitionLoadingProvider;
