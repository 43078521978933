import React, { useState } from 'react';
import './customstyles.css'
import { aemLanguageOptions, preferredPronounsItems, genderItems, sexAssignedBirthItems, maritalStatusItems, ethnicityItems, raceItems } from './staticdata';
import { apicall_PatientData } from './savedata';
import { useAppSelector } from '../../app/hooks';
import { Grid, InputLabel, TextField, Typography } from "@mui/material";

interface Props {
    // Define your component props here
}

const NondisclosureSpa: React.FC<Props> = () => {
    // Component logic goes here     

    const pdata: any = useAppSelector((state) => state.patient);
    /* Modal data start */
    const [formData, setFormData] = useState({
        patientId: pdata.patientId,
        first_name: pdata.first_name,
        last_name: pdata.last_name,
        full_name: pdata.full_name,
    });
    /* Modal data end */

    /* Change and Select events start */
    const today = new Date();
    const formattedDate = `${String(today.getMonth() + 1).padStart(2, '0')}/${String(today.getDate()).padStart(2, '0')}/${today.getFullYear()}`;
    const FN_firstletter=formData.first_name.charAt(0);
    const LN_firstletter = formData.last_name.charAt(0);
    apicall_PatientData(formData);
    /*Save Modal construction end*/
    return (
        <>
            <div style={{ padding: '15px' }} id='mainDiv'>
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }} id="pageOne">
                    <form>
                        <Grid container spacing={2} alignItems="center" lineHeight={2.0}>
                            <Grid item xs={6} textAlign='right'>
                                <img src={`${process.env.PUBLIC_URL}/optum_logo_orange.png`} alt="Optum Logo" style={{ width: '150px', height: '150px', marginRight: '23rem' }} />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h4" gutterBottom style={{ fontSize: '3.5rem', fontWeight: 'bold', color: 'navy' }}>
                                Aviso de no discriminación y aviso
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h4" gutterBottom style={{ fontSize: '3.5rem', fontWeight: 'bold', color: 'navy' }}>
                                de disponibilidad de servicios
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h4" gutterBottom style={{ fontSize: '3.5rem', fontWeight: 'bold', color: 'navy', marginBottom: '1rem' }}>
                                de asistencia en otros idiomas
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h4" gutterBottom style={{ fontSize: '3.5rem', fontWeight: 'bold', color: 'navy', marginBottom: '1rem' }}>
                                y formatos alternativos
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h5" gutterBottom style={{ color: 'navy' }}>
                                <b>Cumplimos* con las leyes de derechos civiles vigentes y no discriminamos por motivos de raza, color,
                                    origen nacional, edad, sexo, religión, ascendencia, identificación de grupo étnico, discapacidad mental,
                                    discapacidad física, condición médica, información genética, estado civil u orientación sexual (incluidos embarazo, orientación
                                    sexual e identidad de género). </b>
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h5" gutterBottom style={{ color: 'navy' }}>
                                <b>No excluimos a las personas ni las tratamos de manera menos favorable por motivos de raza,
                                    color, origen nacional, edad, sexo, religión, ascendencia, identificación de grupo étnico,
                                    discapacidad mental, discapacidad física, condición médica, información genética, estado civil u
                                    orientación sexual (incluidos embarazo, orientación sexual e identidad de género).</b>
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>
                            <div style={{ borderBottom: '3px solid orange', width: '100%', marginBottom: '1rem' }}></div>
                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                                Ofrecemos ayuda y servicios gratuitos para que pueda comunicarse con nosotros. Puede solicitar
                                intérpretes o comunicaciones en otros idiomas o formatos, como letra grande. También ofrecemos
                                modificaciones razonables para las personas con discapacidad.
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem',marginRight:'0.5rem' }}>
                                Si necesita estos servicios, llame al número gratuito
                            </Typography>
                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', color: 'navy' }}>
                                <b>1-800-403-4160</b> (TTY 711).
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                                Si cree que no le hemos prestado estos servicios o que sufrió algún tipo de discriminación
                                por motivos de raza, color, origen nacional, edad, discapacidad o sexo, puede enviar una queja al
                                Coordinador de Derechos Civiles:
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                                <b>Optum Civil Rights Coordinator</b>
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                                1 Optum Circle
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }} >
                                Eden Prairie, MN 55344
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                                Optum_Civil_Rights@optum.com
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem',marginRight:'0.5rem' }}>
                                Si necesita ayuda para presentar una queja, llame al número gratuito
                            </Typography>
                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', color: 'navy' }}>
                                <b>1-888-445-8745 </b>(TTY 711).
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                                También puede presentar una queja de derechos civiles ante la Oficina de Derechos Civiles
                                del Departamento de Salud y Servicios Humanos de los Estados Unidos:
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                                <b>En línea</b>: ocrportal.hhs.gov/ocr/portal/lobby.jsf
                            </Typography>

                        </Grid>
                    </form>
                </div>
                <div style={{ height: '10px' }}></div>
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }} id="pageTwo">
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                            <b>Por teléfono</b>: <b>1-800-368-1019, 800-537-7697 </b>(TDD)
                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                            <b>Por correo</b>: U.S. Department of Health and Human Services
                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', marginLeft: '2.65rem' }}>
                            200 Independence Ave. SW
                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', marginLeft: '2.65rem' }}>
                            Room 509F, HHH Bldg.
                        </Typography>

                    </Grid>

                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', marginLeft: '2.65rem' }}>
                            Washington, D.C. 20201
                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem',marginRight:'0.5rem' }}>
                            Los formularios de queja están disponibles en
                        </Typography>
                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', color: 'navy' }}>
                            hhs.gov/ocr/office/file/index.html
                        </Typography>

                    </Grid>

                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', color: 'navy' }}>
                            <b>Oficina de Derechos Civiles - Departamento de Servicios de Salud de California</b>
                        </Typography>

                    </Grid><Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                            También puede presentar una queja sobre derechos civiles ante la Oficina de Derechos
                            Civiles del Departamento de Servicios de Salud de California por teléfono, por escrito o por correo electrónico:
                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                            <b>• Por teléfono:</b> Llame al 1-916-440-7370. Si no puede hablar o escuchar bien, llame al <b>711 </b>
                        </Typography>
                        <Typography variant="h6" gutterBottom style={{ marginLeft: '0.5rem' }}>
                            <b>(Servicio de Retransmisión de Telecomunicaciones). </b>
                        </Typography>

                    </Grid>

                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                            <b>• Por escrito:</b> Llene un formulario de queja o envíe una carta a:
                        </Typography>

                    </Grid><Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom >
                            Deputy Director, Office of Civil Rights Department of Health Care Services Office of Civil Rights
                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom >
                            P.O. Box 997413, MS 0009
                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                            Sacramento, CA 95899-7413
                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                            Los formularios de queja están disponibles en
                        </Typography>
                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', color: 'navy' }}>
                            <b> dhcs.ca.gov/Pages/Language_Access.aspx </b>
                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem',marginRight:'0.5rem' }}>
                            Este aviso está disponible en
                        </Typography>
                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', color: 'navy' }}>
                            <b> optum.com</b>
                        </Typography>

                    </Grid>

                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom >
                            <b>IMPORTANTE:</b>Si habla inglés, usted dispone de servicios gratuitos de asistencia en
                            otros idiomas y comunicaciones gratuitas en otros formatos, como letra grande. Llame al

                        </Typography>
                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', color: 'navy' }}>
                            <b>1-800-403-4160 </b> (TTY 711).

                        </Typography>

                    </Grid>
                </div>
                <div style={{ height: '10px' }}></div>
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }} id="pageThree">
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                            He recibido y leído el Aviso de No Discriminación y el Aviso de Disponibilidad de Servicios de
                            Asistencia en Otros Idiomas y Formatos Alternativos.

                        </Typography>

                    </Grid>
                    <Grid container spacing={2} alignItems="center" style={{ marginBottom: '40rem' }}>
                        <Grid item xs={1.3}>
                            <InputLabel htmlFor="input1">Inicial</InputLabel>
                        </Grid>
                        <Grid item xs={2.5}>
                            <TextField id="standard-basic" label="" variant="standard" value={`${FN_firstletter} ${LN_firstletter}`} fullWidth />
                        </Grid>
                        <Grid item xs={1.3}>
                            <InputLabel htmlFor="input1">Fecha</InputLabel>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                        <img src={`${process.env.PUBLIC_URL}/optum_logo_orange.png`} alt="Optum Logo" style={{ width: '150px', height: '150px', color: 'orange', marginRight: '20rem' }} />
                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', fontSize: '15px' }}>
                            *HealthCare Partners Affiliates Medical Group, Centers for Family Medicine, GP, Beaver Medical Group, P.C., Physician Medical Group of
                            San Jose, Inc., Pinnacle Medical Group, Inc., AppleCare Hospitalists Medical Group, Inc., AppleCare Medical Group St Francis, Inc., AppleCare Medical Group,
                            Inc., HealthCare Partners Associates Medical Group, P.C., HealthCare Partners Medical Group, P.C., Monarch Healthcare, a Medical Group, Inc., San Diego Physicians Medical Group, Inc.,
                            que opera como Scripps Physicians Medical Group, Inland Faculty Medical Group, Inc.

                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', fontSize: '15px' }}>
                            La compañía no discrimina por motivos de raza, color, origen nacional, sexo, edad ni discapacidad en programas
                            y actividades de salud. Ofrecemos servicios gratuitos para ayudarle a que se comunique con nosotros. Por ejemplo,
                            cartas en otros idiomas o en letra grande. O bien, usted puede pedir un intérprete. Para pedir ayuda, llame al
                            1-800-403-4160, TTY 711. ATENCIÓN: Si habla español (Spanish), hay servicios de asistencia de idiomas, sin cargo, a su
                            disposición. Llame al 1-800-403-4160, TTY 711.: (Chinese), 我們免費為您提供語言協助 服務。 請致電: 1-800-403-4160。

                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', fontSize: '15px' }}>
                            Optum es una marca registrada de Optum, Inc. en los Estados Unidos y en otras jurisdicciones. Todas las demás marcas
                            comerciales son propiedad de sus respectivos titulares. Dado que mejoramos continuamente nuestros productos y servicios,
                            Optum se reserva el derecho de cambiar las especificaciones sin previo aviso. Optum es una empresa que promueve la igualdad de oportunidades.

                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', fontSize: '15px' }}>

                            © 2024 Optum, Inc. Todos los derechos reservados. 15159147

                        </Typography>

                    </Grid>
                </div>
            </div>
        </>
    );
};

export default NondisclosureSpa;