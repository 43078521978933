import React from "react";
import { useAppSelector } from "../../../app/hooks";
import {
  AemContentTypes,
  AemKVPair as IAemKVPair,
  AemGetOptions,
  AemLanguageCodes,
  AemCategoryVisitTypes,
  AemPersonaTypes,
  IAemDebugFlags,
} from "../AemDefs";
import { AemClient } from "../AemClient";

// this is a workaround to re-export the interface again for convenience
export interface AemKVPair extends IAemKVPair {}

const warnColor: string = "#FFD700";
const errorColor: string = "#EE0010";
const successColor: string = "#18A468";

interface AemProps {
  cid: string;
  type?: AemContentTypes; // reserved for future use. indicates the rendered content type. specific to AEM content fragments.
  listPos?: number; // optional. position index if we are expecting to retrieve an item from a AEM content fragment list
  persona?: AemPersonaTypes | boolean; // AemGetOptions - whether to check for better subvariant keys based on current personal
  categoryVisitType?: AemCategoryVisitTypes; // AemGetOptions - optional visit type subvariant key to try to retrieve.
  transformTokens?: boolean; // AemGetOptions - should we transform tokens in AEM content when used
  transformFallback?: boolean; // should we transform tokens in fallback content when used
  aemMissing?: any; // used to keep track of aem content fragments that are missing
  aemDeprecated?: any; // used to keep track of deprecated aem content fragments
  ariaAlt?: string;
  children?: React.ReactElement<any, any> | string[] | string;
}

type AemComponent = React.FC<AemProps> & {
  client: () => AemClient;
  get: any;
  getList: any;
  getListItem: any;
  getListKVPairs: any;
  getListKVPairItem: any;
  getListKVPairValue: any;
  getListKVPairKey: any;
  parseListKVPairKey: any;
  parseEmailListValue: any;
  toKvPairs: any;
  transformContentTokens: any;
  ContentTypes: any;
  PersonaTypes: any;
  LanguageCodes: any;
};

/**
 * The Aem component is a wrapper for the active AemClient instance.
 * This component allows us to easily load and display dynamic AEM content fragments from the AEM server.
 * This solution enables us to display dynamic content variations based on various properties such as the
 * CDO, persona, languageCode, etc.
 *
 * Component Examples
 * <Aem cid="PROP_ID">Fallback Content</Aem>
 * <Aem cid="PROP_ID" />
 * <Aem cid="FOOBAR" aemMissing>Fallback Content</Aem>    // aemDebugMode places orange box around missing content
 *
 * Direct Access Examples
 * Aem.get("PROP_ID", "Fallback Content");
 *
 * Direct Access Lists Examples
 * Aem.getList("LIST_ID", ["A", "B", "C"]);
 *
 * @param props
 * @returns element or fragment containing AEM content
 */
export const Aem: AemComponent = ({
  cid,
  listPos,
  type = AemContentTypes.auto,
  persona = true,
  categoryVisitType,
  transformTokens = true,
  transformFallback = true,
  ariaAlt,
  children,
  aemMissing,
  aemDeprecated,
}: AemProps) => {
  // used to force re-render after aem content gets reloaded when the language changes
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _aemRefresh = useAppSelector((state) => state?.aem?.refresh);

  const aemClient: AemClient = AemClient.getInstance();
  const aemEnabled: boolean = aemClient.enabled;
  const aemDbgMode: boolean = aemClient.debugMode;
  const aemDbgFlags: IAemDebugFlags = aemClient.debugFlags;
  const aemWarn: boolean = aemMissing;
  let aemError: boolean = false;
  let aemSuccess: boolean = false;
  let content: any = undefined;

  if (aemEnabled) {
    const aemOpts: AemGetOptions = { persona, categoryVisitType, transformTokens };
    if (listPos !== undefined) {
      content = aemClient.getListItem(cid, listPos, null, aemOpts);
    } else {
      content = aemClient.get(cid, null, aemOpts);
    }
    if (content === undefined || content === null) {
      content = children;
      aemError = true;

      // replace the variables in the fallback content
      if (transformTokens && transformFallback && content) {
        content = aemClient.transformContentTokens(content);
      }

      if (aemDbgMode) {
        content = <React.Fragment>?AEM? {content}</React.Fragment>;
      }
    }
    aemSuccess = !aemError && !aemWarn;

    if (aemDbgMode) {
      if (aemError) {
        if (aemDbgFlags.error) {
          content = <span style={{ border: "1px dashed " + errorColor }}>{content}</span>;
        }
      } else if (aemWarn) {
        if (aemDbgFlags.warn) {
          content = <span style={{ border: "1px dashed " + warnColor }}>{content}</span>;
        }
      } else if (aemSuccess) {
        if (aemDbgFlags.success) {
          content = <span style={{ border: "1px dashed " + successColor }}>{content}</span>;
        }
      }
    }
  } else {
    content = children;
  }

  let renderedContent = content;
  if (type === AemContentTypes.imageUrl) {
    renderedContent = <img src={content} alt={ariaAlt || ""}></img>;
  }

  return <React.Fragment>{renderedContent}</React.Fragment>;
};

// add some static const aliases
Aem.ContentTypes = AemContentTypes;
Aem.PersonaTypes = AemPersonaTypes;
Aem.LanguageCodes = AemLanguageCodes;

// add some static convenience helpers so we can just reference Aem instead of AemClient
Aem.client = () => AemClient.getInstance();
Aem.get = AemClient.prototype.get.bind(AemClient.getInstance());
Aem.getList = AemClient.prototype.getList.bind(AemClient.getInstance());
Aem.getListItem = AemClient.prototype.getListItem.bind(AemClient.getInstance());
Aem.getListKVPairs = AemClient.prototype.getListKVPairs.bind(AemClient.getInstance());
Aem.getListKVPairItem = AemClient.prototype.getListKVPairItem.bind(AemClient.getInstance());
Aem.getListKVPairValue = AemClient.prototype.getListKVPairValue.bind(AemClient.getInstance());
Aem.getListKVPairKey = AemClient.prototype.getListKVPairKey.bind(AemClient.getInstance());
Aem.parseListKVPairKey = AemClient.prototype.parseListKVPairKey.bind(AemClient.getInstance());
Aem.parseEmailListValue = AemClient.prototype.parseEmailListValue.bind(AemClient.getInstance());
Aem.toKvPairs = AemClient.prototype.toKvPairs.bind(AemClient.getInstance());
Aem.transformContentTokens = AemClient.prototype.transformContentTokens.bind(AemClient.getInstance());

export default Aem;
export { AemContentTypes, AemLanguageCodes, AemPersonaTypes };