import React, { useState } from 'react';
import './customstyles.css'
import { aemLanguageOptions, preferredPronounsItems, genderItems, sexAssignedBirthItems, maritalStatusItems, ethnicityItems, raceItems } from './staticdata';
import { apicall_PatientData } from './savedata';
import { useAppSelector } from '../../app/hooks';
import { Grid, InputLabel, TextField, Typography } from "@mui/material";

interface Props {
    // Define your component props here
}

const Nondisclosure: React.FC<Props> = () => {
    // Component logic goes here     

    const pdata: any = useAppSelector((state) => state.patient);
    /* Modal data start */
    const [formData, setFormData] = useState({
        patientId: pdata.patientId,
        first_name: pdata.first_name,
        last_name: pdata.last_name,
        full_name: pdata.full_name,
    });
    /* Modal data end */

    /* Change and Select events start */
    const today = new Date();
    const formattedDate = `${String(today.getMonth() + 1).padStart(2, '0')}/${String(today.getDate()).padStart(2, '0')}/${today.getFullYear()}`;
    const FN_firstletter = formData.first_name.charAt(0);
    const LN_firstletter = formData.last_name.charAt(0);
    apicall_PatientData(formData);
    /*Save Modal construction end*/
    return (
        <>
            <div style={{ padding: '15px' }} id='mainDiv'>
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }} id="pageOne">
                    <form>
                        <Grid container spacing={2} alignItems="center" lineHeight={2.0}>
                            <Grid item xs={6} textAlign='right'>
                                <img src={`${process.env.PUBLIC_URL}/optum_logo_orange.png`} alt="Optum Logo" style={{ width: '150px', height: '150px', marginRight: '23rem' }} />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h4" gutterBottom style={{ fontSize: '3.5rem', fontWeight: 'bold', color: 'navy' }}>
                                Notice of nondiscrimination and notice
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h4" gutterBottom style={{ fontSize: '3.5rem', fontWeight: 'bold', color: 'navy' }}>
                                of availability of language assistance
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h4" gutterBottom style={{ fontSize: '3.5rem', fontWeight: 'bold', color: 'navy', marginBottom: '1rem' }}>
                                services and alternate formats
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h5" gutterBottom style={{ color: 'navy' }}>
                                <b>We* comply with applicable civil rights laws and do not discriminate on the basis of race,
                                    color, national origin, age, sex, religion, ancestry, ethnic group identification, mental
                                    disability, physical disability, medical condition, genetic information, marital status, or
                                    sexual orientation (including pregnancy, sexual orientation, and gender identity). </b>
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h5" gutterBottom style={{ color: 'navy' }}>
                                <b>We do not exclude people or treat them less favorably because of race, color, national
                                    origin, age, sex, religion, ancestry, ethnic group identification, mental disability, physical
                                    disability, medical condition, genetic information, marital status, or sexual orientation
                                    (including pregnancy, sexual orientation, and gender identity)</b>
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>
                            <div style={{ borderBottom: '3px solid orange', width: '100%', marginBottom: '1rem' }}></div>
                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                                We provide free aids and services to help you communicate with us. You can ask for interpreters
                                and/or for communications in other languages or formats such as large print. We also provide
                                reasonable modifications for persons with disabilities
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem',marginRight:'0.5rem' }}>
                                If you need these services, call the toll-free number
                            </Typography>
                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', color: 'navy' }}>
                                <b>1-800-403-4160</b> (TTY 711).
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                                If you believe that we failed to provide these services or discriminated in another way on the
                                basis of race, color, national origin, age, disability, or sex, you can send a complaint to the Civil
                                Rights Coordinator:
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                                <b>Optum Civil Rights Coordinator</b>
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                                1 Optum Circle
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }} >
                                Eden Prairie, MN 55344
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                                Optum_Civil_Rights@optum.com
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' ,marginRight:'0.5rem'}}>
                                If you need help filing a complaint, call the toll-free number
                            </Typography>
                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', color: 'navy' }}>
                                <b>1-888-445-8745 </b>(TTY 711).
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                                You can also file a civil rights complaint with the U.S. Department of Health and Human Services,
                                Office for Civil Rights:
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                                <b>Online</b>: ocrportal.hhs.gov/ocr/portal/lobby.jsf
                            </Typography>

                        </Grid>
                        <Grid container item xs={12}>

                            <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                                <b>Phone</b>: <b>1-800-368-1019, 800-537-7697 </b>(TDD)
                            </Typography>

                        </Grid>
                    </form>
                </div>
                <div style={{ height: '10px' }}></div>
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }} id="pageTwo">
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                            <b>Mail</b>: U.S. Department of Health and Human Services
                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', marginLeft: '2.65rem' }}>
                            200 Independence Ave. SW
                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', marginLeft: '2.65rem' }}>
                            Room 509F, HHH Bldg
                        </Typography>

                    </Grid>

                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', marginLeft: '2.65rem' }}>
                            Washington, D.C. 20201
                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>
                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', marginRight: '0.5rem' }}>
                            Complaint forms are available at
                        </Typography>
                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', color: 'navy' }}>
                            hhs.gov/ocr/office/file/index.html
                        </Typography>
                    </Grid>

                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', color: 'navy' }}>
                            <b>Office of Civil Rights — California Department of Health Care Services </b>
                        </Typography>

                    </Grid><Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                            You can also file a civil rights complaint with the California Department of Health Care Services,
                            Office of Civil Rights by phone, in writing, or electronically:
                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                            <b>• By phone:</b> Call 1-916-440-7370. If you cannot speak or hear well, please call <b>711 </b>
                        </Typography>
                        <Typography variant="h6" gutterBottom style={{ marginLeft: '0.5rem' }}>
                            <b>(Telecommunications Relay Service). </b>
                        </Typography>

                    </Grid>

                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                            <b>• In writing:</b> Fill out a complaint form or send a letter to
                        </Typography>

                    </Grid><Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom >
                            Deputy Director, Office of Civil Rights Department of Health Care Services Office of Civil Rights
                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom >
                            P.O. Box 997413, MS 0009
                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                            Sacramento, CA 95899-7413
                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem',marginRight:'0.5rem' }}>
                            Complaint forms are available at
                        </Typography>
                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', color: 'navy' }}>
                            <b> dhcs.ca.gov/Pages/Language_Access.aspx </b>
                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' ,marginRight:'0.5rem'}}>
                            This notice is available at optum.com at
                        </Typography>
                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', color: 'navy' }}>
                            <b> optum.com</b>
                        </Typography>

                    </Grid>

                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom >
                            <b>ATTENTION:</b> If you speak English, free language assistance services and free communications in
                            other formats, such as large print, are available to you. Call

                        </Typography>
                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', color: 'navy' }}>
                            <b>1-800-403-4160 </b> (TTY 711).

                        </Typography>

                    </Grid>
                </div>
                <div style={{ height: '10px' }}></div>
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }} id="pageThree">
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem' }}>
                            I have received and read the Notice of Nondiscrimination and Notice of Availability of Language
                            Assistance Services and Alternate Formats.

                        </Typography>

                    </Grid>
                    <Grid container spacing={2} alignItems="center" style={{ marginBottom: '50rem' }}>
                        <Grid item xs={1.3}>
                            <InputLabel htmlFor="input1">Initial</InputLabel>
                        </Grid>
                        <Grid item xs={2.5}>
                            <TextField id="standard-basic" label="" variant="standard" value={`${FN_firstletter} ${LN_firstletter}`}  fullWidth />
                        </Grid>
                        <Grid item xs={1.3}>
                            <InputLabel htmlFor="input1">Date</InputLabel>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                        <img src={`${process.env.PUBLIC_URL}/optum_logo_orange.png`} alt="Optum Logo" style={{ width: '150px', height: '150px', color: 'orange', marginRight: '20rem' }} />
                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', fontSize: '15px' }}>
                            *HealthCare Partners Affiliates Medical Group, Centers for Family Medicine, GP, Beaver Medical Group, P.C., Physician
                            Medical Group of San Jose, Inc., Pinnacle Medical Group, Inc., AppleCare Hospitalists Medical Group, Inc., AppleCare Medical
                            Group St Francis, Inc., AppleCare Medical Group, Inc., HealthCare Partners Associates Medical Group, P.C., HealthCare
                            Partners Medical Group, P.C., Monarch Healthcare, a Medical Group, Inc., San Diego Physicians Medical Group, Inc., dba
                            Scripps Physicians Medical Group, Inland Faculty Medical Group, Inc.

                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', fontSize: '15px' }}>
                            The company does not discriminate on the basis of race, color, national origin, sex, age, or disability in health programs
                            and activities. We provide free services to help you communicate with us. This includes letters in other languages or large
                            print. Or, you can ask for an interpreter. To ask for help, please call 1-800-403-4160, TTY 711. ATENCIÓN: Si habla español
                            (Spanish), hay servicios de asistencia de idiomas, sin cargo, a su disposición. Llame al 1-800-403-4160, TTY 711. 請注意 ：
                            如果您說中文 (Chinese) ，我們免費為您提供語言協助服務 。請致電 ：1-800-403-4160 。

                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', fontSize: '15px' }}>
                            Optum is a registered trademark of Optum, Inc. in the U.S. and other jurisdictions. All other trademarks are the property
                            of their respective owners. Because we are continuously improving our products and services, Optum reserves the right to
                            change specifications without prior notice. Optum is an equal opportunity employer.

                        </Typography>

                    </Grid>
                    <Grid container item xs={12}>

                        <Typography variant="h6" gutterBottom style={{ marginBottom: '1rem', fontSize: '15px' }}>

                            © 2024 Optum, Inc. All rights reserved. 15159147

                        </Typography>

                    </Grid>
                </div>
            </div>
        </>
    );
};

export default Nondisclosure;