import React, { useState } from "react";
import clsx from "clsx";
import useId from "@mui/material/utils/useId";
import { Grid, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import { AccAutoFillTokens } from "../../data/accessibility/AccessibilityData";
import { appColors, appFonts, appStyles, useAppTheme } from "../../theme";
import { AppButton } from "../Buttons";
import Aem from "../../lib/aem/components/Aem";

const useStyles = makeStyles(() => ({
  overlayScrim: {
    position: "fixed",
    height: "100%",
    backgroundColor: appColors.blackScrim,
    overflow: "auto",
  },
  overlayHeader: {
    position: "relative",
    marginTop: "30px",
    marginBottom: "5px",
  },
  searchIcon: {
    width: "22px",
    height: "22px",
    margin: "10px 22px 0 22px",
    color: appColors.white,
  },
  searchInputArea: {
    width: "100%",
  },
  searchInputTextField: {
    width: "100%",
    fontFamily: appFonts.regular,
    fontSize: "18px",
    color: appColors.white,
  },
  searchInputInput: {
    color: appColors.white,
    ...appStyles.inputUnderlineBorder,

    "&.MuiInputBase-root": {
      color: appStyles.white,
    },
  },
  cancelButtonContainer: {
    margin: "0 15px",
    justifyContent: "center",
  },
  cancelButton: {
    fontFamily: appFonts.medium,
    fontWeight: "unset",
    minWidth: "80px",
    minHeight: "32px",
    borderRadius: "16px",
    lineHeight: "initial",
    letterSpacing: "initial",
    color: appColors.white,
    "&.MuiButton-outlined": {
      textTransform: "initial",
      borderColor: appColors.white,
    },
  },
  choicesBox: {
    maxHeight: "100%",
    paddingLeft: "60px",
    paddingBottom: "20px",
  },
  choicesRow: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: "49px",
    padding: "5px 16px",
    color: appColors.white,
    fontFamily: appFonts.bold,
    fontSize: "18px",
    lineHeight: "22px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.3)",
    },
  },
}));

interface OverlayProps {
  list?: any;
  ariaLabel?: string;
  onChange?: (val: any) => void;
  onCancel?: () => void;
}

const OverlayComponent: React.FC<OverlayProps> = ({
  list,
  ariaLabel = "",
  onChange,
  onCancel,
}) => {
  const classes = useStyles();
  const theme = useAppTheme();

  const searchInputId = useId();
  const [searchText, setSearchText] = useState("");

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleChange = (val) => {
    // an item has been selected, let the parent component know about it.
    if (onChange) {
      onChange(val);
    }
  };

  const filterOptions = (list, searchText: string) => {
    if (!searchText || searchText.length === 0) {
      return list;
    }
    const searchTextLowered: string = searchText.toLowerCase();

    let newList = list.filter((option) => {
      let strLowered: string = (option || "").toLowerCase();
      return strLowered.includes(searchTextLowered);
    });

    return newList;
  };

  const filteredOptions = filterOptions(list, searchText);

  return (
    <Grid container direction="column" className={clsx(classes.overlayScrim, theme.nowrap)}>
      <Grid container direction="row" justifyContent="space-between" className={clsx(classes.overlayHeader, theme.nowrap)}>
        <Grid item>
          <SearchIcon className={classes.searchIcon} />
        </Grid>
        <Grid item className={classes.searchInputArea}>
          <TextField
            id={searchInputId}
            variant="standard"
            className={classes.searchInputTextField}
            inputProps={{
              "aria-label": ariaLabel,
            }}
            InputProps={{
              classes: {
                underline: classes.searchInputInput,
              },
            }}
            value={searchText}
            autoComplete={AccAutoFillTokens.off}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </Grid>
        <Grid item className={classes.cancelButtonContainer}>
          <AppButton
            variant="outlined"
            disableElevation
            className={classes.cancelButton}
            trackName="cancel"
            trackLocation="overlay"
            onClick={() => {
              setSearchText("");
              handleCancel();
            }}
          >
            <Aem cid="ACTION_CANCELBUTTON_TEXT_1">Cancel</Aem>
          </AppButton>
        </Grid>
      </Grid>
      <Grid container direction="column" className={clsx(classes.choicesBox, theme.nowrap)}>
        {filteredOptions.length > 0 ? (
          filteredOptions.map((option, idx: number) => (
            <Grid
              key={idx}
              item
              xs={12}
              className={classes.choicesRow}
              onClick={() => {
                setSearchText("");
                handleChange(option);
              }}
            >
              {option}
            </Grid>
          ))
        ) : (
          <Grid xs={12} className={classes.choicesRow}>
            No Match Found
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default OverlayComponent;