import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Grid, Skeleton } from "@mui/material";
import {
  appColors,
  appStyles,
  AppThemeColorKeys,
  getThemeBGClass,
  useAppTheme,
} from "../theme";
import { useLoadingState } from "../lib/loadState";
import Header from "./Header";

const skeletonLineHeight: number = 20;
const defaultHeaderBgColor: string = AppThemeColorKeys.mediumGray;
const defaultScrimBgColor: string = AppThemeColorKeys.transparent;

const useStyles = makeStyles({
  root: {
    ...appStyles.absxy,
    backgroundColor: appColors.blackScrim,
    pointerEvents: "none",
    opacity: 0,
    zIndex: 101,
    transition: "opacity 50ms ease-in-out",

    "&.show": {
      "&.showSpinner": {
        opacity: 1,
      },
    },

    "& .MuiSkeleton-root": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
      marginBottom: "15px",
    },
  },
  navContent: {
    flexWrap: "nowrap",
  },
  navHeader: {
    minHeight: "150px",
  },
  content: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  vspacer: {
    marginBottom: "15px",
  },
  wrapper: {
    position: "relative",
    width: "100%",
    // height: "100%",
    padding: "30px",
  },
  skeletonRounded: {
    "&.MuiSkeleton-root": {
      borderRadius: "24px",
    },
  },
});

interface LoadingSkeletonProps {
  show?: boolean;
  showHeader?: boolean;
  headerBgColor?: string;
  scrimBgColor?: string;
  showDelayMS?: number;
  hideDelayMS?: number;
  onLoadingChange?: (val: boolean) => void;
}

const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({
  show = true,
  showHeader = true,
  headerBgColor = defaultHeaderBgColor,
  scrimBgColor = defaultScrimBgColor,
  showDelayMS = 150,
  hideDelayMS = 0,
  onLoadingChange,
}) => {
  const classes = useStyles();
  const theme = useAppTheme();

  const [loadState, loadActions] = useLoadingState({
    autoUpdateSpinner: true,
    showSpinnerDelayMS: showDelayMS,
    hideSpinnerDelayMS: hideDelayMS,
  });
  const showSpinner = loadState.showSpinner;

  const headerColorClass: any = getThemeBGClass(
    theme,
    headerBgColor || defaultHeaderBgColor
  );
  const scrimColorClass: any = show
    ? getThemeBGClass(theme, scrimBgColor || defaultScrimBgColor)
    : null;

  useEffect(() => {
    if (show !== loadState.loading) {
      loadActions.setLoading(show);
    }
  }, [loadActions, loadState.loading, show]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="start"
      className={clsx(classes.root, scrimColorClass, { show, showSpinner })}
    >
      <Grid
        container
        direction="column"
        className={clsx(theme.navContent, classes.navContent)}
      >
        {showHeader && (
          <Grid
            container
            direction="column"
            className={clsx(
              theme.navHeader,
              classes.navHeader,
              headerColorClass
            )}
          >
            <Header center={true} showMenuButton={false} />

            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              className={classes.wrapper}
            >
              <Skeleton
                variant="rectangular"
                width="50%"
                height={1.5 * skeletonLineHeight}
                className={classes.skeletonRounded}
              />
            </Grid>
          </Grid>
        )}

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.wrapper}
        >
          <Skeleton
            variant="rectangular"
            width="50%"
            height={1.5 * skeletonLineHeight}
            className={classes.skeletonRounded}
          />
          <div className={classes.vspacer} />

          <Skeleton
            variant="rectangular"
            width="90%"
            height={1 * skeletonLineHeight}
            className={classes.skeletonRounded}
          />
          <Skeleton
            variant="rectangular"
            width="65%"
            height={1 * skeletonLineHeight}
            className={classes.skeletonRounded}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoadingSkeleton;