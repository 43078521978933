import React, { useState } from 'react';
import './customstyles.css'
import { aemLanguageOptions, preferredPronounsItems, genderItems, sexAssignedBirthItems, maritalStatusItems, ethnicityItems, raceItems } from './staticdata';
import { apicall_PatientData } from './savedata';
import { useAppSelector } from '../../app/hooks';
import { Grid, InputLabel, TextField, Typography,List, ListItem} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface Props {
    // Define your component props here
}

const Authorization: React.FC<Props> = () => {
    // Component logic goes here     

    const pdata: any = useAppSelector((state) => state.patient);
    const persona = useAppSelector((state) => state.preferences.persona);
    const relation = useAppSelector((state) => state.preferences.relationship);
    const representativeName=useAppSelector((state)=>state.preferences.representativeFullName);
    /* Modal data start */
    const [formData, setFormData] = useState({
        patientId: pdata.patientId,
        first_name: pdata.first_name,
        last_name: pdata.last_name,
        full_name: pdata.full_name,

    });
    /* Modal data end */


    const today = new Date();
    const formattedDate = `${String(today.getMonth() + 1).padStart(2, '0')}/${String(today.getDate()).padStart(2, '0')}/${today.getFullYear()}`;
    /* Change and Seelct events end */
    /*Save Modal construction start*/
    apicall_PatientData(formData);
    /*Save Modal construction end*/
    return (
        <>
            <div style={{ padding: '15px' }} id='mainDiv'>
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }} id="pageOne">
                    <form>
                        <Grid container spacing={2} alignItems="center" lineHeight={2.5}>
                            <Grid item xs={6}>
                                <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold' }}>
                                Authorization for use and disclosure
                                </Typography>
                            </Grid>
                            <Grid item xs={6} textAlign='right'>
                                <img src={`${process.env.PUBLIC_URL}/Optum_logo.png`} alt="Optum Logo" style={{ width: '160px', height: '70px' }} />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            
                            <Typography variant="h6" gutterBottom >
                            I authorize <b>Optum and its parent, subsidiary and affiliated organizations</b> to use and disclose my
                            individually identifiable health information between themselves for the purpose of providing me with
                            better treatment, payment facilitation, care coordination and/or case management. Optum takes our
                            patient’s privacy very seriously and will only use this information as required and permitted under the law.
                            </Typography>
                          
                        </Grid>
                        <Grid container item xs={12}>
                            
                            <Typography variant="h6" gutterBottom >
                            <b>Type of information:</b> I authorize these entities to use and disclose all of my health information including
                            medical, pharmacy, dental, vision, mental health, substance use, HIV/AIDS*, psychotherapy, reproductive,
                            genetic, communicable disease and health care program information. This information may include
                            information relating to visits, admissions, treatment, claims, case management or care coordination.
                            </Typography>
                          
                        </Grid>
                        <Grid container item xs={12}>
                            
                        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                          I understand and agree that:
                        </Typography>
                        <List>
                        <ListItem style={{ display: 'list-item', listStyleType: 'disc', marginLeft: '20px', fontSize: '1.25rem'}}>This authorization is voluntary.</ListItem>
                        <ListItem style={{ display: 'list-item', listStyleType: 'disc', marginLeft: '20px', fontSize: '1.25rem'}}>I may not be denied treatment, payment for health care services or enrollment or eligibility for health
                            care benefits if l do not sign this form.</ListItem>
                        <ListItem style={{ display: 'list-item', listStyleType: 'disc', marginLeft: '20px', fontSize: '1.25rem'}}>This authorization will expire two years from the date I sign it. I may revoke or modify this authorization
                            at any time by notifying Optum in writing; however, the revocation/modification will not have an effect
                            on any actions taken prior to the date my revocation is received and processed.</ListItem>
                            
                        </List>
                          
                        </Grid>
                        <Grid container item xs={12}>
                            
                            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                            I certify that I have read the foregoing and have the right to request a copy. As the patient or the
                            patient’s guardian, conservator or general agent, I agree to accept the above terms.
                            </Typography>
                          
                        </Grid>
                        
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                            <Grid item xs={7}>
                            <TextField id="standard-basic" label="" variant="standard" value={persona === "patient" ? `${formData.first_name} ${formData.last_name}` : representativeName} fullWidth inputProps={{style: { fontSize: '1.20rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '1.25rem' }}>Signature of patient or personal representative</InputLabel>
                            </Grid>
                            <Grid item xs={5}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth inputProps={{style: { fontSize: '1.20rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '1.25rem' }}>Date</InputLabel>
                            </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction="column" style={{ minHeight: '100vh' }} spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                            <Grid item xs={7}>
                            <TextField id="standard-basic" label="" variant="standard" value={persona === "patient"? "" : representativeName} fullWidth inputProps={{style: { fontSize: '1.20rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '1.25rem' }}>Personal representative’s name</InputLabel>
                            </Grid>
                            <Grid item xs={5}>
                            <TextField id="standard-basic" label="" variant="standard" value={persona === "patient" ? "Self" : relation} fullWidth inputProps={{style: { fontSize: '1.20rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '1.25rem' }}>Relationship to patient/minor</InputLabel>
                            </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ flexGrow: 1 }} />
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom sx={{ fontSize: '1rem' }}>
                            *Special release needed for HIV test results.
                            </Typography>
                            <Typography variant="body1" gutterBottom sx={{ fontSize: '1rem' }}>
                            If you are a guardian or court-appointed representative, you must provide a copy of your legal authorization to represent the patient.
                            </Typography>
                            <Typography variant="body1" gutterBottom sx={{ fontSize: '1rem' }}>
                            The company does not discriminate on the basis of race, color, national origin, sex, age, or disability in health programs and activities.
                            We provide free services to help you communicate with us. This includes letters in other languages or large print. Or, you can ask for an
                            interpreter. To ask for help, please call 1-800-403-4160, TTY 711. ATENCIÓN: Si habla español (Spanish), hay servicios de asistencia de
                            idiomas, sin cargo, a su disposición. Llame al 1-800-403-4160, TTY 711. 請注意 ：如果您說中文 (Chinese) ，我們免費為您提供
                            語言協助服務 。請致電 ：1-800-403-4160, TTY 711 。
                            </Typography>
                            <Typography variant="body1" gutterBottom sx={{ fontSize: '1rem' }}>
                            Optum is a registered trademark of Optum, Inc. in the U.S. and other jurisdictions. All other trademarks are the property of
                            their respective owners. Because we are continuously improving our products and services, Optum reserves the right to change
                            specifications without prior notice. Optum is an equal opportunity employer.
                            </Typography>
                            <Typography variant="body1" gutterBottom sx={{ fontSize: '1rem' }}>
                            © 2024 Optum, Inc. All rights reserved. 23685 01/24
                            </Typography>
                            <Typography variant="h6" gutterBottom textAlign="right">
                            1
                            </Typography>
                        </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Authorization;