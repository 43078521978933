import React, { useRef, useState } from "react";
import useId from "@mui/material/utils/useId";
import { Grid, Dialog, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useWindowWidth } from "@wojtekmaj/react-hooks";
import { TransitionProps } from "@mui/material/transitions";
import { Document, Page,pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import clsx from "clsx";
import { tracker } from "../appTracker";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { appColors, appFonts, useAppTheme, appDialogTransparentPaperProps } from "../theme";
import { TermsOfUseUrl, PrivacyUrl, TermsOfUseVersion } from "../data/docData";
import { gotoNextTaskStep, setTaskTransitionPending } from "../features/task/taskSlice";
import { patientAgreeToTermsOfUseAPI } from "../api/PatientAgreeToTermsOfUseAPI";
import { UpdateConsentToTextAPI } from "../api/UpdateConsentToTextAPI";
import Header from "../Components/Header";
import NavButton from "../Components/Buttons/NavButton";
import { AppButton } from "../Components/Buttons";
import CustomCheckbox from "../Components/CustomCheckbox";
import { CreatePdfDialogLinkStr } from "../utility/linkUtilities";
import Aem from "../lib/aem/components/Aem";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const useStyles = makeStyles({
  root: {
    position: "relative",
    color: "#ffffff",
    width: "100%",
    height: "100%",
    fontFamily: appFonts.regular,
  },
  content: {
    marginTop: "45px",
    padding: "0 16px 0 16px",
  },
  label1: {
    marginBottom: "23px",
    fontFamily: appFonts.bold,
    fontSize: "26px",
  },
  label2: {
    fontFamily: appFonts.medium,
    fontSize: "20px",
  },
  label3: {
    fontFamily: appFonts.medium,
    fontSize: "20px",
    maxWidth: "350px !important",
    lineHeight: "1.5",
  },
  contactConsentArea: {
    maxWidth: "320px",
    marginTop: "55px",
    marginBottom: "55px",
    padding: "25px 0",
    flexWrap: "nowrap",
    fontFamily: appFonts.medium,
    fontSize: "14px",
    textAlign: "left",
    borderTop: `1px solid ${appColors.whiteA01}`,
    borderBottom: `1px solid ${appColors.whiteA01}`,
  },
  contactConsentCheckboxArea: {
    paddingRight: "15px",
  },
  contactConsentTextArea: {
  },
  checkbox: {
    padding: "0",
  },
  logo: {
    height: "24px",
    width: "82.37px",
    alignItems: "center",
    minHeight: "70px",
    paddingTop: "16px",
    paddingBottom: "30px",
  },
  btnCancel: {
    fontFamily: appFonts.medium,
    fontWeight: "unset",
    maxWidth: "80px",
    maxHeight: "32px",
    borderRadius: "16px",
    lineHeight: "initial",
    letterSpacing: "initial",
    color: appColors.white,
    "&.MuiButton-outlined": {
      textTransform: "initial",
      borderColor: appColors.white,
    },
    textTransform: "none",
    backgroundColor: "rgba(255,255,255,0.1)",
    border: "1px solid #FFFFFF",
    fontSize: "16px",
  },
  pdfHeader: {
    fontFamily: appFonts.medium,
    fontSize: "22px",
    lineHeight: "40px",
    color: appColors.white,
    paddingTop: "20px",
    paddingBottom: "30px",
    paddingLeft: "16px",
    paddingRight: "16px",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  pdfLink: {
    textDecorationColor: appColors.white,
    cursor: "pointer"
  },
  pdfPage: {
    "& .react-pdf__Document": {
      "& .react-pdf__Page": {
        "& .react-pdf__Page__canvas": {
          marginBottom: "15px",
        }
      },
    }
  }
});


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface TermsAndConditionsPageProps {
  pid?: string;
}



const TermsAndConditionsPage: React.FC<TermsAndConditionsPageProps> = (props: TermsAndConditionsPageProps) => {
  const classes = useStyles();
  const theme = useAppTheme();
  const dispatch = useAppDispatch();
  const width = useWindowWidth();

  const contactConsentInputId = useId();
  const dialogTitleId = useId();

  const patientId: string = useAppSelector((state) => state.patient.patientId);
  const patientFullName: string = useAppSelector((state) => state.patient.full_name);
  const oldConsentToText: boolean = useAppSelector<boolean>((state) => state.patient.consentToText);
  const showConstentToText: boolean = !oldConsentToText;

  // used to force re-render after aem content gets reloaded when the language changes
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let _aemRefresh: any = useAppSelector((state) => state?.aem?.refresh);

  // Dialog modal 
  const termsPdfLink: string = TermsOfUseUrl;
  const privacyPdfLink: string = PrivacyUrl;
  const [numPages, setNumPages] = useState<any>(9);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [activePdfName, setActivePdfName] = useState<string>();
  const [activePdfLink, setActivePdfLink] = useState<any>();
  const [contactConsentSelected, setContactConsentSelected] = useState<boolean>(true);    // default the checkbox to checked if it is shown

  function onViewPDFClick(args) {
    setActivePdfName(args.label);
    setActivePdfLink(args.pdfLink);
    setOpenDialog(true);
  }
  
  const agreeButtonLabel: string = Aem.get("ACTION_AGREEBUTTON_TEXT_1", "I Agree");
  
  // NOTE: we need to replace the links here on this page rather than in AEM 
  // so that we can dynamically load and bind the inner labels
  const termsLabel: string = Aem.get("BODY_TERMS_TEXT_4", "Terms of Use");
  const privacyLabel: string = Aem.get("BODY_TERMS_TEXT_5", "Privacy Policy");
  const termsLink: string = CreatePdfDialogLinkStr(onViewPDFClick, termsLabel, termsPdfLink, "terms", "body");
  const privacyLink: string = CreatePdfDialogLinkStr(onViewPDFClick, privacyLabel, privacyPdfLink, "privacy", "body");

  const disclaimerText: string = Aem.get(
    "BODY_TERMS_TEXT_3",
    "By continuing, you are agreeing to our {{TERMS_LINK}} and our {{PRIVACY_LINK}}",
  )
    .replace("{{TERMS_LINK}}", termsLink)
    .replace("{{PRIVACY_LINK}}", privacyLink);

  const contactConsentText: string = Aem.get(
    "BODY_TERMS_TEXT_7",
    "By checking the box, you agree to the texting terms of use. Text messaging is not a secure method of communication and carries some risk of being read by a third party. Messages may be recurring and include personal health information. Messaging and data rates may apply."
  );

  const handleAgreeClick = async () => {
    dispatch(setTaskTransitionPending(true));
    tracker.formStarted("terms");

    if (showConstentToText && contactConsentSelected) { 
      await UpdateConsentToTextAPI(patientId, contactConsentSelected).catch(() => false);
    } 
    // else, nothing to change if unselected

    await patientAgreeToTermsOfUseAPI(patientId, patientFullName, TermsOfUseVersion).catch(() => false);
    await dispatch(gotoNextTaskStep());
  };

  const handleContactConsentCheckboxChange = (e) => {
    let val: boolean = !!e.target.checked;
    setContactConsentSelected(val);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const pageRefs = useRef({});
  const onItemClick = ({ pageNumber }) =>
    pageRefs.current[pageNumber].scrollIntoView({ behavior: 'smooth' });

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container direction="column"
        className={clsx(theme.navContent, theme.nowrap)}>
        <Grid
          container
          direction="column"
          className={clsx(theme.navHeader, theme.bgOrangeGrad)}
          style={{ height: "80px" }}
        >
          <Header center={true} showMenuButton={true} />
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          className={clsx(theme.textCenter, classes.content)}
        >
          <Grid item className={classes.label1}>
            <Aem cid="BODY_TERMS_TEXT_1">Terms and Conditions of use</Aem>
          </Grid>
          <Grid item className={classes.label2}>
            <Aem cid="BODY_TERMS_TEXT_2"></Aem>
          </Grid>
          <Grid item className={classes.label3}>
            <div dangerouslySetInnerHTML={{ __html: disclaimerText }}></div>
          </Grid>

          {showConstentToText && (
            <Grid item>
              <Grid 
                container
                direction="row"
                alignItems="start"

                className={classes.contactConsentArea}
              >
                <Grid item className={classes.contactConsentCheckboxArea}>
                  <CustomCheckbox              
                    checkboxProps={{
                      id: contactConsentInputId,
                      className: classes.checkbox,
                      checked: contactConsentSelected,
                      required: true,
                      onChange: handleContactConsentCheckboxChange,
                    }}
                  />
                </Grid>
                <Grid item className={classes.contactConsentTextArea}>
                  {contactConsentText}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        className={theme.navStickyFooter}
        style={{
          alignItems: "center",
          justifyContent: "center",
          padding: "0 16px 70px"
        }}
      >
        <Grid item xs={6}>
          <NavButton
            accentColor={"orange"}
            label={agreeButtonLabel}
            fullWidth={true}
            trackName="i agree"
            trackLocation="nav footer"
            onClick={handleAgreeClick}
          />
        </Grid>
      </Grid>

      <Dialog
        fullScreen={true}
        disableRestoreFocus={true}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        TransitionComponent={Transition}
        aria-labelledby={dialogTitleId}
        PaperProps={appDialogTransparentPaperProps}
      >
        <Grid
          style={{
            height: "100%",
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.pdfHeader}
          >
            <Grid
              item
              id={dialogTitleId}
              className={theme.headerTitle}
              style={{ marginBottom: "0px", width: "70%" }}
            >
              {activePdfName}
            </Grid>
            <Grid item style={{ display: "flex" }}>
              <AppButton
                variant="outlined"
                disableElevation
                className={classes.btnCancel}
                trackName="cancel"
                trackLocation="overlay"
                onClick={() => setOpenDialog(false)}
              >
                <Aem cid="ACTION_CLOSEBUTTON_TEXT_1">Close</Aem>
              </AppButton>
            </Grid>
          </Grid>
          {/* pdf content */}
          <Grid className={classes.pdfPage}
            container
            direction="column"
            style={{ alignContent: "center", paddingBottom: "20px" }}
          >
            {activePdfLink !== undefined
              && (
                <Document
                  file={activePdfLink}
                  onLoadSuccess={onDocumentLoadSuccess}
                  externalLinkTarget="_blank"
                  onItemClick={onItemClick}
                >
                  {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map(page => (
                      <div key={page} ref={el => { pageRefs.current[page] = el; }}>
                         <Page
                             renderTextLayer={false}
                             pageNumber={page}
                             width={width?? 300}
                             loading=""
                         />
                         </div>
                    ))}
                   
                    
                </Document>
              )}
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default TermsAndConditionsPage;
