import React from "react";
import MaskedInput from "react-text-mask";
import useId from "@mui/material/utils/useId";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { InputLabelProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { appColors, appStyles, appFonts } from "../theme";
import { AccAutoFillTokens } from "../data/accessibility/AccessibilityData";

const useLabelStyles = makeStyles((theme) => ({
  root: {
    fontFamily: appFonts.semibold,
    color: "rgba(255,255,255,0.7)",
    transform: "translate(0, 7px) scale(0.75);",

    "&.MuiFormLabel-root.Mui-focused": {
      color: "rgba(255,255,255,0.7)",
    },
    "&.MuiInputLabel-filled.MuiInputLabel-shrink": {
      color: "rgba(255,255,255,0.7)",
    },
  },
}));

const useInputStyles = makeStyles((theme) => ({
  root: {
    color: appColors.white,
    backgroundColor: "transparent",

    "&.MuiInput-input": {
      color: appColors.white,
    },
    "&.MuiInput-underline": {
      ...appStyles.inputUnderlineColors,
    },
    "&.MuiFilledInput-underline": {
      ...appStyles.inputUnderlineColors,
    },
    "& .MuiFilledInput-input": {
      paddingLeft: "0",
    },
  },

  focused: {},
}));

function PhoneMaskCustom(props) {
  const { ...other } = props;
  return (
    <MaskedInput
      {...other}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
    />
  );
}

const CustomPhoneField: React.FC<TextFieldProps> = ({
  autoComplete = AccAutoFillTokens.off,
  ...props
}) => {
  const labelClasses = useLabelStyles();
  const inputClasses = useInputStyles();

  const inputId = useId();

  const inputLabelProps: Partial<InputLabelProps> = {
    classes: labelClasses,
  };

  return (
    <TextField
      id={inputId}
      variant="standard"
      InputLabelProps={inputLabelProps}
      InputProps={{ inputComponent: PhoneMaskCustom, classes: inputClasses }}
      autoComplete={autoComplete}
      {...props}
    />
  );
};

export default CustomPhoneField;