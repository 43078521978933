import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "@mui/material";
import useIsMounted from "../../lib/hooks/useIsMounted";
import { tracker } from "../../appTracker";
import { VisuallyHidden } from "../Accessibility/VisuallyHidden";
import { Aem } from "../../lib/aem";

const DefaultScreenReaderExtLinkHintText: string = "(Opens a new window)";

type LinkButtonProps = any & {
  className?: any;
  url: string;
  target?: string;
  rel?: string;
  disabled?: boolean;
  linkProps: any;
  trackName?: string;
  trackLocation?: string;
  screenReaderHintText?: string; // undefined to load AEM value
  children?: React.ReactElement<any, any> | string[] | string;
  // NOTE: make sure to use async/await inside handler to prevent button mashing
  onClick?: (...args) => void;
};

export const LinkButton: React.FC<LinkButtonProps> = ({
  className,
  url,
  target = "_blank",
  rel = "noopener",
  disabled = false,
  linkProps,
  onClick,
  screenReaderHintText = Aem.get(
    "ACCESSIBILITY_EXTLINK_TEXT_1",
    DefaultScreenReaderExtLinkHintText
  ),
  children,
  trackName = "app button",
  trackLocation = "body",
}) => {
  // used to disable the button when an async click action is occurring
  // this prevents button mashing
  const [clickPending, setClickPending] = useState<boolean>(false);
  const isMounted = useIsMounted();

  const handleClick = async (...args) => {
    tracker.click(trackName, trackLocation);

    if (onClick) {
      setClickPending(true);

      try {
        await onClick(...args);
      } catch (ex) {}

      if (isMounted()) {
        setClickPending(false);
      }
    }
  };

  return (
    <Link
      className={clsx("ext-link", className)}
      href={url}
      target={target}
      rel={rel}
      disabled={disabled || clickPending}
      onClick={handleClick}
      {...linkProps}
    >
      {children}
      {screenReaderHintText && (
        <VisuallyHidden>{screenReaderHintText}</VisuallyHidden>
      )}
    </Link>
  );
};

export default LinkButton;