import React from "react";
import clsx from "clsx";
import { Grid } from "@mui/material";
import { useAppTheme, getThemeBGClass } from "../theme";

interface HDividerProps {
  color?: string;
}

const HDivider: React.FC<HDividerProps> = ({ color = "white" }) => {
  const theme = useAppTheme();
  const colorClass = getThemeBGClass(theme, color);

  return (
    <Grid item className={clsx(theme.hdivider, colorClass)}></Grid>
  );
};

export default HDivider;