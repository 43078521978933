import React from "react";
import clsx from "clsx";

type VisuallyHiddenProps = {
  as?: any;           // element to render as.  defaults to <span />
  className?: any;
  children?: React.ReactElement<any, any>;
};

/**
 * VisuallyHidden
 *
 * Provides text for accessibility screen readers that is visually hidden to normal users.
 * It is the logical opposite of the "aria-hidden" attribute.
 * NOTE: this component only works if the .sr-only { ... } css class is defined by the application
 */
export const VisuallyHidden: React.FC<VisuallyHiddenProps> = ({
  as: Component = "span",
  className,
  children,
  ...attrs
}: VisuallyHiddenProps) => {
  return (
    <Component className={clsx("sr-only", className)} {...attrs}>
      {children}
    </Component>
  );
};